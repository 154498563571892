<template>
  <div class="">

    <div class="flex justify-center">
      <easy-spinner
          v-if="charge && allMarque.length === 0 && allGamme.length === 0"
          type="dots"
          size="30"
      />
    </div>

    <div class="flex" v-if="allMarque.length > 0">
      <div class="w-1/2">
        <div class="text-c12 lab text-left">
          Type d’accessoire
        </div>
        <div class="mt-2">
          <multiselect
              :option="optionType"
              width="32%"
              :value-t="valueType"
              @info="retourType"
          />
        </div>
      </div>

      <div class="w-1/2 ml-12">
        <div class="text-c12 lab text-left">
          Intitulé
        </div>
        <div class="mt-2">
          <inputo
              placeholder="DC2312"
              @info="retourNom"
          />
        </div>
      </div>
    </div>

    <div class="flex mt-8" v-if="optionMarque.length > 0">
      <div class="w-1/2">
        <div class="text-c12 lab text-left">
          Marque
        </div>
        <div class="mt-2">
          <multiselect
              :option="optionMarque"
              width="32%"
              :value-t="valueMarque"
              @info="retourMarque"
          />
        </div>
      </div>

      <div class="w-1/2 ml-12">
        <div class="text-c12 lab text-left">
          Etat
        </div>
        <div class="mt-2">
          <multiselect
              :option="optionEtat"
              width="32%"
              :value-t="valueEtat"
              @info="retourEtat"
          />
        </div>
<!--        <div class="text-c12 lab text-left">-->
<!--          Gamme-->
<!--        </div>-->
<!--        <div class="mt-2">-->
<!--          <multiselect-->
<!--              :option="optionGamme"-->
<!--              width="32%"-->
<!--              :value-t="valueGamme"-->
<!--              @info="retourGamme"-->
<!--          />-->
<!--        </div>-->
      </div>
    </div>

<!--    <div class="flex mt-8">-->
<!--      <div class="w-1/2">-->
<!--        <div class="text-c12 lab text-left">-->
<!--          Etat-->
<!--        </div>-->
<!--        <div class="mt-2">-->
<!--          <multiselect-->
<!--              :option="optionEtat"-->
<!--              width="32%"-->
<!--              :value-t="valueEtat"-->
<!--              @info="retourEtat"-->
<!--          />-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="w-1/2 ml-12">-->
<!--      </div>-->
<!--    </div>-->
    {{ sendValue }}
  </div>
</template>

<script>
import back from '../../../../assets/icons/backBut.svg'
import multiselect from '../../../helper/form/multiselect'
import inputo from '../../../helper/form/input'
import apiroutes from '../../../../router/api-routes'
import http from '../../../../plugins/https'


export default {
  name: "Index",

  components: {
    multiselect,
    inputo
  },

  data () {
    return {
      icons: {
        back
      },
      allMarque: [],
      optionMarque: [],
      valueMarque: '',
      allGamme: [],
      optionGamme: ['Haut de gamme', 'Moyen'],
      valueGamme: '',
      valueNom: '',
      optionType: ['CORDONS', 'ESSUIS', 'ETUIS', 'SOLUTION POUR LENTILLE', 'LENTILLES', 'PRODUIT D\'ENTRETIEN'],
      valueType: 'CORDONS',
      optionEtat: ['Bon état', 'Défectueux vendable', 'Défectueux'],
      valueEtat: 'Bon état',
      charge: true,
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
      if (this.valueNom.length > 0 && this.valueMarque !== '' && this.valueType !== '') {
        let obj = {
          brands: this.valueMarque,
          name: this.valueNom,
          range: [],
          quantity: 0,
          price: 0,
          type: "ACCESSORY",
          hasTax: false,
          frameState: 'GOOD',
          accessoryType: 'CORDON'
        }

        for (let i in this.allMarque) {
          if (this.valueMarque === this.allMarque[i].label){
            obj.brands = [ this.allMarque[i].id ]
          }
        }

        // for (let i in this.allGamme) {
        //   if (this.valueGamme === this.allGamme[i].label){
        //     obj.range = [ this.allGamme[i].id ]
        //   }
        // }

        if (this.valueType === this.optionType[1]) {
          obj.accessoryType = 'WIPE'
        }
        if (this.valueType === this.optionType[2]) {
          obj.accessoryType = 'CASES'
        }
        if (this.valueType === this.optionType[3]) {
          obj.accessoryType = 'LENS_SOLUTION'
        }
        if (this.valueType === this.optionType[4]) {
          obj.accessoryType = 'LENS'
        }
        if (this.valueType === this.optionType[5]) {
          obj.accessoryType = 'GLASSES_CLIP'
        }

        if (this.valueEtat === this.optionEtat[1]) {
          obj.frameState = 'SALABLE'
        }
        if (this.valueEtat === this.optionEtat[2]) {
          obj.frameState = 'DEFECTIVE'
        }

        this.$emit('info', obj)
      }
    }
  },

  created () {
    this.allMarques()
    // this.allGammes()
  },

  methods: {
    allMarques () {
      http.get(apiroutes.baseURL + apiroutes.allMarque)
          .then(response => {
            this.allMarque = response.reverse()
            let tab = []
            for (let item in this.allMarque) {
              tab.push(this.allMarque[item].label)
            }
            this.valueMarque = tab[0]
            this.optionMarque = tab
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    // allGammes () {
    //   http.get(apiroutes.baseURL + apiroutes.allGamme)
    //       .then(response => {
    //         console.log(response)
    //         this.allGamme = response.reverse()
    //         let tab = []
    //         for (let item in this.allGamme) {
    //           tab.push(this.allGamme[item].label)
    //         }
    //         this.valueGamme = tab[0]
    //         this.optionGamme = tab
    //         this.charge = false
    //       })
    //       .catch(error => {
    //         this.charge = false
    //         console.log(error)
    //       })
    // },

    retourMarque (answer) {
      this.valueMarque = answer
    },

    retourEtat (answer) {
      this.valueEtat = answer
    },

    retourNom (answer) {
      this.valueNom = answer
    },

    // retourGamme (answer) {
    //   this.valueGamme = answer
    // },

    retourType (answer) {
      this.valueType = answer
    },

    retourCategorie (answer) {
      this.valueCategorie = answer
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.lab{
  color: #32324D;
}
</style>
