<template>
  <div class="">
    <div class="flex">
      <div class="w-1/2">
        <div class="text-c12 lab text-left">
          Photos de l’accessoire
        </div>
        <div class="mt-2">
          <upload @info="retourImage" />
        </div>
      </div>

      <!--      <div class="w-1/2 ml-12">-->
      <!--        <div class="mt-6 pt-1">-->
      <!--          <upload @info="retourImage2" />-->
      <!--        </div>-->
      <!--      </div>-->
    </div>

    {{ sendValue }}
  </div>
</template>

<script>
import upload from '../../../helper/form/upload'

export default {
  name: "Index",

  components: {
    upload
  },

  data () {
    return {
      image: null,
      // image2: null
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
      if (this.image !== null) {
        let obj = {
          image1: this.image
        }
        this.$emit('info', obj)
      }
    }
  },

  methods: {
    retourImage (answer) {
      this.image = answer
    },

    // retourImage2 (answer) {
    //   this.image2 = answer
    // }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.lab{
  color: #32324D;
}
</style>
