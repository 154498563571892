<template>
  <div class="">
    <div class="flex justify-center">
      <easy-spinner
          v-if="allGamme.length === 0 || allMarque.length === 0"
          type="dots"
          size="30"
      />
    </div>

    <div class="flex">
      <div class="w-1/2">
        <div class="text-c12 lab text-left">
          Catégorie de monture
        </div>
        <div class="mt-2">
          <multiselect
              :option="optionCategorie"
              width="32%"
              :value-t="valueCategorie"
              @info="retourCategorie"
          />
        </div>
      </div>

      <div class="w-1/2 ml-12">
        <div class="text-c12 lab text-left">
          Type de monture
        </div>
        <div class="mt-2">
          <multiselect
              :option="optionType"
              width="32%"
              :value-t="valueType"
              @info="retourType"
          />
        </div>
      </div>
    </div>

    <div class="mt-6">
      <div class="flex">
        <div class="w-1/2">
          <div class="text-c12 lab text-left">
            Matière
          </div>
          <div class="mt-2">
            <multiselect
                :option="optionMatiere"
                width="33%"
                :value-t="valueMatiere"
                @info="retourMatiere"
            />
          </div>
        </div>

        <div class="w-1/2 ml-12" v-if="allMarque.length > 0">
          <div class="text-c12 lab text-left">
            Marque
          </div>
          <div class="mt-2">
            <multiselect
                :option="optionMarque"
                width="33%"
                :value-t="valueMarque"
                @info="retourMarque"
            />
          </div>
        </div>
      </div>

      <div class="flex mt-8">
        <div class="w-1/2" v-if="allGamme.length > 0">
          <div class="text-c12 lab text-left">
            Gamme
          </div>
          <div class="mt-2">
            <multiselect
              :option="optionGamme"
              width="33%"
              :value-t="valueGamme"
              @info="retourGamme"
            />
          </div>
        </div>

        <div class="w-1/2 ml-12">
          <div class="text-c12 lab text-left">
            Code de la monture
          </div>
          <div class="mt-2">
            <inputo
              placeholder="DC45"
              @info="retourReference"
            />
          </div>
        </div>
      </div>

      <div class="flex mt-8">
        <div class="w-1/2">
          <div class="text-c12 lab text-left">
            1/2 Ecart monture
          </div>
          <div class="mt-2">
            <inputo
                @info="retourYeux"
            />
          </div>
        </div>

        <div class="w-1/2 ml-12">
          <div class="text-c12 lab text-left">
            Taille de pont
          </div>
          <div class="mt-2">
            <inputo
                @info="retourPont"
            />
          </div>
        </div>
      </div>

      <div class="flex mt-8">
        <div class="w-1/2">
          <div class="text-c12 lab text-left">
            Taille des branches
          </div>
          <div class="mt-2">
            <inputo
                @info="retourBranche"
            />
          </div>
        </div>

        <div class="w-1/2 ml-12">
          <div class="text-c12 lab text-left">
            Code Couleur
          </div>
          <div class="mt-2">
            <inputo
                placeholder="Rouge"
                @info="retourCouleur"
            />
          </div>
        </div>
      </div>

      <div class="flex mt-8">
        <div class="w-1/2">
          <div class="text-c12 lab text-left">
            Etat
          </div>
          <div class="mt-2">
            <multiselect
                :option="optionEtat"
                width="32%"
                :value-t="valueEtat"
                @info="retourEtat"
            />
          </div>
        </div>

        <div class="w-1/2 ml-12">
          <div class="text-c12 lab text-left">
            Modèle
          </div>
          <div class="mt-2">
            <multiselect
                :option="optionDestinataire"
                width="32%"
                :value-t="valueDestinataire"
                @info="retourDestinataire"
            />
          </div>
        </div>
      </div>


    </div>
    {{ sendValue }}
  </div>
</template>

<script>
import back from '../../../../assets/icons/backBut.svg'
import multiselect from '../../../helper/form/multiselect'
import inputo from '../../../helper/form/input'
import apiroutes from '../../../../router/api-routes'
import http from '../../../../plugins/https'

export default {
  name: "Index",

  components: {
    multiselect,
    inputo
  },

  data () {
    return {
      icons: {
        back
      },
      allMarque: [],
      allGamme: [],
      optionMarque: [],
      valueMarque: '',
      optionGamme: [],
      valueGamme: '',
      optionType: ['NYLOR', 'PERCEE', 'CERCLEE'],
      valueType: 'NYLOR',
      optionMatiere: ['METALIQUE', 'ACETATE', 'METALLIC ALLOY ACETATE'],
      valueMatiere: 'METALIQUE',
      optionCategorie: ['Solaire', 'Optique'],
      valueCategorie: 'Solaire',
      optionEtat: ['Bon état', 'Défectueux vendable', 'Défectueux'],
      valueEtat: 'Bon état',
      optionDestinataire: ['Homme', 'Femme', 'Mixte', 'Enfant'],
      valueDestinataire: 'Homme',
      valueReference: '',
      valueCouleur: '',
      charge: true,
      code: '',
      yeux: '',
      pont: '',
      branche: ''
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
      if (this.valueReference.length > 0 && this.valueCouleur.length > 0) {
        let obj = {
          name: 'MONTURE ' + this.valueMarque + ' ' + this.valueReference + ' ' + this.valueCouleur,
          brands: this.valueMarque,
          ref: this.valueReference,
          range: this.valueGamme,
          color: this.valueCouleur,
          frameCategory: 'SOLAR',
          frameType: this.valueType,
          frameMaterial: 'METALLIC',
          type: 'FRAMES',
          frameState: 'GOOD',
          frameGender: 'MAN',
          frameData: {
            eyesHeight: this.yeux,
            branchHeight: this.branche,
            bridgeHeight: this.pont
          }
        }

        if (this.valueCategorie === this.optionCategorie[1]) {
          obj.frameCategory = 'OPTICAL'
        }

        for (let i in this.allMarque) {
          if (this.valueMarque === this.allMarque[i].label){
            obj.brands = [ this.allMarque[i].id ]
          }
        }

        for (let i in this.allGamme) {
          if (this.valueGamme === this.allGamme[i].label){
            obj.range = [ this.allGamme[i].id ]
          }
        }

        if (this.valueMatiere === this.optionMatiere[1]) {
          obj.frameMaterial = 'ACETATE'
        }

        if (this.valueMatiere === this.optionMatiere[2]) {
          obj.frameMaterial = 'METALLIC_ALLOY_ACETATE'
        }

        if (this.valueEtat === this.optionEtat[1]) {
          obj.frameState = 'SALABLE'
        }
        if (this.valueEtat === this.optionEtat[2]) {
          obj.frameState = 'DEFECTIVE'
        }

        if (this.valueDestinataire === this.optionEtat[1]) {
          obj.frameGender = 'WOMAN'
        }
        if (this.valueDestinataire === this.optionEtat[2]) {
          obj.frameGender = 'CHILD'
        }
        if (this.valueDestinataire === this.optionEtat[3]) {
          obj.frameGender = 'MIXTE'
        }
        this.$emit('info', obj)
      }
    }
  },

  created () {
    this.allMarques()
    this.allGammes()
  },

  methods: {
    retourMarque (answer) {
      this.valueMarque = answer
    },

    retourEtat (answer) {
      this.valueEtat = answer
    },

    retourBranche (answer) {
      this.branche = answer
    },

    retourYeux (answer) {
      this.yeux = answer
    },

    retourPont (answer) {
      this.pont = answer
    },

    retourMonture (answer) {
      this.code = answer
    },

    retourMatiere (answer) {
      this.valueMatiere = answer
    },

    retourReference (answer) {
      this.valueReference = answer
    },

    retourGamme (answer) {
      this.valueGamme = answer
    },

    retourCouleur (answer) {
      this.valueCouleur = answer
    },

    retourCategorie (answer) {
      this.valueCategorie = answer
    },

    retourType (answer) {
      this.valueType = answer
    },

    allMarques () {
      http.get(apiroutes.baseURL + apiroutes.allMarque)
          .then(response => {
            this.allMarque = response.reverse()
            let tab = []
            for (let item in this.allMarque) {
              tab.push(this.allMarque[item].label)
            }
            this.valueMarque = tab[0]
            this.optionMarque = tab
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    allGammes () {
      http.get(apiroutes.baseURL + apiroutes.allGamme)
          .then(response => {
            console.log(response)
            this.allGamme = response.reverse()
            let tab = []
            for (let item in this.allGamme) {
              tab.push(this.allGamme[item].label)
            }
            this.valueGamme = tab[0]
            this.optionGamme = tab
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.lab{
  color: #32324D;
}
</style>
