<template>
  <div class="">
    <div class="flex w-3/4">
      <div class="w-2/3">
        <div class="mt-2">
          <inputo
            placeholder="120"
            type="number"
            @info="retourAmount"
          />
        </div>
      </div>

      <div class="w-1/3 ml-6">
        <div class="mt-2">
          <multiselect
            :option="option"
            width="15%"
            :value-t="valueType"
            @info="retourType"
          />
        </div>
      </div>
    </div>

    {{ sendValue }}
  </div>
</template>

<script>
import back from '../../../../assets/icons/backBut.svg'
import multiselect from '../../../helper/form/multiselect'
import inputo from '../../../helper/form/input'

export default {
  name: "Index",

  components: {
    multiselect,
    inputo
  },
  props: {
    index: {
      type: Number,
      default: null
    },
    option: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
      icons: {
        back
      },
      optionType: [],
      valueType: '',
      valueAmount: '',
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
      if (this.valueAmount.length > 0 && this.valueType !== '') {
        let obj = {
          index: this.index,
          type: this.valueType,
          amount: this.valueAmount,
        }
        this.$emit('info', obj)
      }
    }
  },

  created () {
    this.optionType = this.option
  },

  methods: {
    retourType (answer) {
      this.valueType = answer
    },

    retourAmount (answer) {
      this.valueAmount = answer
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.lab{
  color: #32324D;
}

</style>
