<template>
  <div
    class="radio pl-4 pr-4 flex items-center"
    :class="{'cocher': position === index}"
    :style="{'height': height}"
  >
    <div
      class="flex w-full h-full cursor-pointer items-center"
      @click="activer()"
    >
      <div class="w-6 h-6 rounded-50 bord p-1" :class="{'activate': position === index}">
        <div class="w-full h-full rounded-50 bg-oho-blue interne"></div>
      </div>
      <div class="w-auto lab ml-3" :style="{'font-size': size}"> {{ index }} </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Index',
  components: {
  },

  props: {
    position: {
      type: String,
      default: null
    },
    index: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '14px'
    },
    height: {
      type: String,
      default: '50px'
    }
  },

  data () {
    return {
      active: null
    }
  },

  methods: {
    activer () {
      this.active = this.index
      this.$emit('radioStat', this.active)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables";
.radio{
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  border-radius: 10px;
}

.lab{
  font-weight: normal;
  color: #606060;
  font-family: 'Mulish', sans-serif;
}
.svg-fill{
  fill: white;
}
.radio:hover{
  border: 1px solid $oho-blue;
}
.cocher{
  border: 1px solid $oho-blue;
}
.bord{
  border: 1px solid #C0C0CF;
  box-sizing: border-box;
  border-radius: 31px;
  .interne {
    display: none;
  }
}
.activate{
  border: 1px solid $oho-blue;
  .interne {
    display: block;
  }
}

</style>
