<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full pt-6 p-6 pb-12"
        >
          <div class="text-left flex pl-6 pr-6">
            <div class="w-full flex justify-end">
              <icon
                :data="icons.fermer"
                height="30"
                width="30"
                class="cursor-pointer"
                original
                @click="fermer"
              />
            </div>
          </div>

          <div class="w-full flex text-90 text-c18 font-c4 text-left mt-6">
            <div class="w-1/2">
              NOM COMMERCIAL
            </div>

            <div class="w-1/5 ml-4">
              MARQUE
            </div>

            <div class="w-1/5 ml-2">
              GAMME
            </div>

            <div class="w-32 ml-2">
              QUANTITÉ
            </div>
          </div>

          <div class="item mt-6 mb-10" />

          <div v-for="(item, index) in allProduct" :key="index" class="mt-4">
            <liste :donne="item"/>
          </div>

          <div class="item mt-6 mb-10" />

          <div
              v-if="charge"
              class="flex justify-center mt-6"
          >
            <easy-spinner
                type="dots"
                size="70"
            />
          </div>

          <div class="mt-6 flex items-center justify-center" v-if="allAgence.length > 0">
            <div class="text-90 text-c18">Sélectionner le site bénéficiaire</div>
            <div class="w-1/5 ml-6">
              <multiselect :option="allAgence" defilePosition="top" height="54.4px" @info="retourSite"/>
            </div>

            <div class="w-1/6 ml-6">
              <bouton height="54.4px" weight="600px" label="Transférer" @oga="continuer"/>
            </div>
          </div>

          <div v-if="error !== null" class="mt-6 text-red-alert"> {{ error }} </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import ic_agence from '../../assets/icons/agenceGray.svg'
import fermer from '../../assets/icons/fermer.svg'
import liste from '../cdevs/product/transfereListe'
import multiselect from '../helper/form/multiselect'
import bouton from '../helper/add/button'
import apiroutes from '../../router/api-routes'
import http from '../../plugins/https'

export default {
  name: 'Success',
  components: { PopupBase, liste, multiselect, bouton },
  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donne: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      wantToMakeOffer: false,
      icons: {
        ic_agence,
        fermer
      },
      error: null,
      charge: false,
      allProduct: [{}, {}, {}, {}, {}],
      allAgence: [],
      agence: null,
      donnes: []
    }
  },

  created() {
    this.wantToMakeOffer = this.activation
    this.charge = true
    this.allInfo()
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    allInfo(){
      http.get(apiroutes.baseURL + apiroutes.allAgency)
          .then(response => {
            console.log(response)
            this.donnes = response
            let tab = []
            for (let item in response) {
              tab.push(response[item].label)
            }
            this.allAgence = tab
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    continuer () {
      this.charge = true
      this.error = null
      if (this.agence !== null) {
        for (let item in this.donnes) {
          if (this.donnes[item].label === this.agence) {
            this.$emit('oga', this.donnes[item])
          }
        }

      } else {
        this.error = 'Veuillez selectionner une agence pour continuer'
      }

    },

    retourSite (answer) {
      this.agence = answer
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
    },

    retourQte (answer) {
      this.qte = answer
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 85%;
    }

    .item{
      border-top: 1px solid #DDDDDD;
    }

    img {
      object-fit: cover;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: white;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .button {
        width: 100%;
        font-size: 16px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
