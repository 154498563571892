<template>
  <div class="p-10 pr-16">
    <newPrestation
      v-if="activePrestation"
      :activation="activePrestation"
      @oga="retourPrestation"
    />

    <transfere
        v-if="activeTransfere"
        :activation="activeTransfere"
        @oga="retourTransfereExec"
    />

    <success
        v-if="activeSuccess"
        :activation="activeSuccess"
        :message="message"
        @oga="retourSuccess"
    />

    <confirmation
        v-if="activeConfirmation & selectedAgence !== null"
        :activation="activeConfirmation"
        :agence="selectedAgence.label"
        @oga="retourConfirmation"
    />

    <new-prix
        v-if="activePrix"
        :activation="activePrix"
        @oga="retourPrix"
    />

    <div
      v-if="!activeAdd"
      class="flex items-center"
    >
      <div class="w-1/2 flex text-left text-c48 items-center font-c6">
        <icon
          :data="icons.back"
          height="70"
          width="70"
          class="mr-2 cursor-pointer"
          original
        />
        <div class="ml-4">
          Produits
        </div>
      </div>

      <div class="w-1/2">
        <div class="flex justify-end">
          <div class="w-4/6 mr-6">
            <bouton
              label="Transfère de produits"
              radius="5.4px"
              weight="600"
              background="#A4D2C5"
              size="17px"
              @oga="retourTransfere"
              v-if="etape === 'Montures' || etape === 'Accessoires'"
            />
          </div>
          <div class="w-4/6">
            <bouton
              :label="buttonLabel"
              radius="5.4px"
              weight="600"
              size="17px"
              @oga="retourClick"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="!activeAdd"
      class="mt-6"
    >
      <global-view @label="retourEtape"  v-if="!reloadSession"/>
    </div>

    <div v-if="activeAdd">
      <addVerre
        v-if="etape === 'Verres'"
        @oga="retourBack"
      />
      <add-monture
        v-if="etape === 'Montures'"
        @oga="retourBack"
      />
      <newAccessoire
        v-if="etape === 'Accessoires'"
        @oga="retourBack"
      />
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import bouton from '../../component/helper/add/button'
import globalView from '../../component/cdevs/product/globalData'
import addVerre from '../../component/cdevs/product/newVerre'
import addMonture from '../../component/cdevs/product/newMonture'
import newAccessoire from '../../component/cdevs/product/newAccessoire'
import newPrestation from '../../component/popup/newPrestation'
import newPrix from '../../component/popup/newPrix'
import transfere from '../../component/popup/transfere'
import confirmation from '../../component/popup/confirmTransfere'
import success from '../../component/popup/success'

export default {
  name: "Index",

  components: {
    bouton,
    globalView,
    addVerre,
    addMonture,
    newAccessoire,
    newPrestation,
    newPrix,
    transfere,
    confirmation,
    success
  },

  data () {
    return {
      icons: {
        back
      },
      buttonLabel: 'Ajouter un verre',
      etape: 'Verres',
      activeAdd: false,
      activePrestation: false,
      activePrix: false,
      reloadSession: false,
      activeTransfere: false,
      activeAlert: false,
      activeConfirmation: false,
      selectedAgence: null,
      activeSuccess: false,
      message: ''
    }
  },

  methods: {
    retourClick(answer){
      if (answer && this.etape !== 'Prestations' && this.etape !== 'Prix'){
        console.log('ici')
        this.activeAdd = true
      }
      if (this.etape === 'Prestations') {
        this.activePrestation = true
      }
      if (this.etape === 'Prix') {
        this.activePrix = true
      }
    },

    retourTransfere (answer) {
        this.activeTransfere = true
    },

    retourSuccess (answer) {
      this.activeSuccess = answer
    },

    retourConfirmation (answer) {
      if (answer) {
        this.message = 'Transfert effectué vers agence ' + this.selectedAgence.label + ' avec succès.'
        this.activeSuccess = true
      }
      this.activeConfirmation = false
    },

    retourTransfereExec (answer) {
      this.activeTransfere = false
      if (answer) {
        this.selectedAgence = answer
        this.activeConfirmation = true
      }
    },

    retourBack(answer){
      this.activeAdd = answer
    },

    retourPrestation(answer){
      this.activePrestation = answer
    },

    retourPrix(answer){
      if (answer === true){
        this.reloadSession = answer

        setTimeout(() => {
          this.reloadSession = false
        }, 200)
      }
      this.activePrix = false
    },

    retourEtape(answer){
      this.etape = answer
      if (answer === 'Verres') {
        this.buttonLabel = 'Ajouter un verre'
      }
      if (answer === 'Montures') {
        this.buttonLabel = 'Ajouter une monture'
      }
      if (answer === 'Accessoires') {
        this.buttonLabel = 'Ajouter un accessoires'
      }
      if (answer === 'Prestations') {
        this.buttonLabel = 'Ajouter une prestation'
      }
      if (answer === 'Prix') {
        this.buttonLabel = 'Ajouter un prix'
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
</style>
