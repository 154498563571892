<template>
  <div class="">
    <updatePrix :activation="activeUpdate" v-if="activeUpdate" :donne="item" @oga="retourUpdate"/>
    <delete-price :activation="activeDelete" v-if="activeDelete" @oga="retourDelete"/>
    <div class="w-full flex text-c18 font-c4 text-left items-center item">
      <div
        class="w-3/6 flex items-center"
        @click="activeDefile = false"
      >
        <icon
          :data="icons.prix"
          height="50"
          width="50"
          class="cursor-pointer"
          original
        />
        <div class="ml-6 pr-32">
          <div class="text-c16 font-c4">
            {{item.label}}
          </div>
          <div class="text-B text-c13">
            <span v-if="item.isForLife">Prix fixe</span>
            <span v-if="!item.isForLife">Prix promotionnel</span>
          </div>
        </div>
      </div>

      <div
        class="w-1/6 ml-2 text-center"
        @click="activeDefile = false"
      >
        <span v-if="item.startDate !== null"> {{conversion(item.startDate)}} </span>
        <span v-if="item.startDate === null"> - </span>
      </div>

      <div
        class="w-1/6 ml-2 text-center"
        @click="activeDefile = false"
      >
        <span v-if="item.endDate !== null"> {{conversion(item.endDate)}} </span>
        <span v-if="item.endDate === null"> - </span>
      </div>

      <div
          v-if="charge"
          class="flex justify-center mt-16"
      >
        <easy-spinner
            type="dots"
            size="70"
        />
      </div>

      <div class="w-20 ml-6 text-left">
        <icon
          :data="icons.option"
          height="20"
          width="20"
          class="cursor-pointer"
          original
          @click="activeDefile =! activeDefile"
        />

        <div
          v-if="activeDefile"
          class="popover text-c16 cursor-pointer"
        >
          <div class="defil p-4" @click="activeUpdate = true; activeDefile = false">
            Modifier
          </div>
          <div class="text-red defil p-4" @click="activeDelete =! activeDelete">
            Supprimer ce prix
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import prix from '../../../../assets/icons/prix.svg'
import option from '../../../../assets/icons/option.svg'
import updatePrix from '../../../popup/updatePrix'
import deletePrice from '../../../popup/confirmDelete'
import apiroutes from '../../../../router/api-routes'
import http from '../../../../plugins/https'

export default {
  name: "Index",
  components: {
    updatePrix,
    deletePrice
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      icons: {
        prix,
        option
      },
      allVerre: ['', '', '', '', '', '', ''],
      position: false,
      activeDefile: false,
      activeUpdate: false,
      activeDelete: false,
      charge: false
    }
  },
  methods: {
    activCheck(){
      this.position = ! this.position
    },

    conversion (index) {
      return new Date(index).toLocaleDateString()
    },

    retourUpdate (answer) {
      if (answer === true) {
        this.$emit('reload', true)
      }
      this.activeUpdate = false
    },

    retourDelete (answer) {
      this.activeDefile = false
      if (answer === true) {
        this.charge = true
        http.delete(apiroutes.baseURL + apiroutes.deletePrice + '?id=' + this.item.id)
            .then(response => {
              this.charge = false
              console.log(response)
              this.$emit('deleteItem', this.item.id)
            })
            .catch(error => {
              this.charge = false
              console.log(error)
            })
      }
      this.activeDelete = false
    }
  },
}
</script>

<style lang="scss" scoped>

.svg-icon{
  fill: transparent;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}
.popover{
  position: absolute;
  background-color: white;
  box-shadow: 0px 0px 20px 0px #0000004a;
  border-radius: 6px;
}
.defil:hover{
  background-color: #b7e2f8;
  padding: 10px;
}
</style>
