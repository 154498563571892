<template>
  <div class="">
    <div class="flex items-center">
      <div class="w-2/3 flex text-left text-c48 items-center font-c6">
        <icon
          :data="icons.back"
          height="70"
          width="70"
          class="mr-2 cursor-pointer"
          original
          @click="fermer"
        />
        <div class="ml-4">
          Nouvelle accessoire
        </div>
      </div>
    </div>

    <div class="mt-6 rounded-10 h-auto bg-white w-full p-8 pl-14 pr-14">
      <div class="text-c20 font-c6 text-left">
        Informations commerciales
      </div>

      <div class="mt-10">
        <form1 @info="retourform1"/>

        <form3 class="mt-20" @info="retourform3"/>

        <formAchat class="mt-20" type="mont" @info="retourAchat" />

        <form2
          class="mt-20"
          @info="retourform2"
        />

        <stock
            class="mt-20"
            @info="retourStock"
        />

        <form4
          class="mt-12"
          @info="retourform4"
        />
      </div>

      <div class="w-full mt-6">
        <div class="text-left text-c12 mt-8">
          Observation
        </div>
        <textare
            class="mt-2"
            background="#F2F5F9"
            color="#606060"
            radius="5px"
            @info="retourNote"
        />
      </div>
    </div>


    <div
      v-if="error !== null"
      class="mt-6 text-red text-c16"
    >
      {{ error }}
    </div>
    <div class="mt-12 flex justify-end">
      <div class="w-1/3 flex">
        <bouton
          label="Annuler"
          background="white"
          radius="6px"
          color="#303030"
          class="w-1/3"
          @oga="fermer"
        />
        <bouton
          label="Ajouter le accessoire"
          :charge="charge"
          radius="6px"
          class="w-2/3 ml-6"
          @oga="continuer"
        />
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../../assets/icons/backBut.svg'
import form1 from './accessoire/form1'
import form2 from './accessoire/form2'
import form4 from './accessoire/form4'
import form3 from './monture/form3'
import stock from './monture/formStock'
import formAchat from './verre/formAchat'
import bouton from '../../helper/add/button'
import http from "../../../plugins/https"
import apiroutes from "../../../router/api-routes"
import textare from '../../helper/form/textarea'

export default {
  name: "Index",

  components: {
    form1,
    form2,
    form4,
    bouton,
    form3,
    formAchat,
    textare,
    stock
  },

  data () {
    return {
      icons: {
        back
      },
      response: {},
      error: null,
      charge: false
    }
  },
  methods: {
    fermer(){
      this.$emit('oga', false)
    },

    retourform1 (answer) {
      this.response.form1 = answer
    },

    retourform2 (answer) {
      this.response.form2 = answer
    },

    retourform4 (answer) {
      this.response.image = answer
    },

    retourform3 (answer) {
      this.response.form3 = answer
    },

    retourAchat (answer) {
      this.response.achat = answer
    },

    retourNote (answer) {
      this.response.note = answer
    },

    retourStock (answer) {
      this.response.stock = answer
    },

    continuer () {
      if (this.response.form1 !== undefined && this.response.form2 !== undefined && this.response.form3 !== undefined) {
        this.charge = true
        const body = this.response.form1
        body.quantity = this.response.form3.quantity
        body.price = this.response.achat.amount
        body.hasSecond = false
        body.sellType = 'PRINCIPAL'
        body.hasTax = this.response.achat.hasTax
        body.supplier = this.response.form3.supplier
        body.primaryLimitation = 0
        body.observation = this.response.note
        this.save(body)
      } else {
        this.error = 'Assurez vous d\'avoir entrer toutes les données de l\'accessoire pour continuer'
      }
    },

    save (body) {
      http.post(apiroutes.baseURL + apiroutes.createProduct, body)
          .then(response => {
            this.savePrice(response.id)
            this.saveStock(response.id)
          })
          .catch(error => {
            this.charge = false
            console.log(error)

            if (error.data.message === 'Please create principal stock agency'){
              this.error = "L'agence de Stock n'existe pas. Veuillez l'ajouter et ressayer"
            } else {
              this.error = "Une erreur s'est produite. Veuillez ressayer"
            }
          })
    },

    savePrice (id) {
      const price = this.response.form2
      for (let i in price) {
        price[i].product = id
      }

      http.post(apiroutes.baseURL + apiroutes.saveProductPrice, {prices: price})
          .then(response => {
            this.$emit('oga', false)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = "Problème de connexion. Veuillez ressayer"
          })
    },

    saveStock (id) {
      const stock = this.response.stock

      for (let i in stock) {
        stock[i].product = id
      }

      http.post(apiroutes.baseURL + apiroutes.addProductStock, {data: stock})
          .then(response => {
            console.log(response)
            this.charge = false
            this.$emit('oga', false)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = "Problème de connexion. Veuillez ressayer"
          })
    },
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.bloc{
  background-color: #FFF6E6;
}
</style>
