<template>
  <div class="">
    <update-fournisor
      v-if="activeUpdate"
      :activation="activeUpdate"
      type="METIER"
      @oga="retourUpdate"
    />
    <div class="w-full flex text-c18 font-c4 text-left items-center item">
      <div class="w-3/5 flex items-center">
        <icon
          :data="icons.config"
          height="50"
          width="50"
          class="cursor-pointer"
          original
        />
        <div class="ml-6 pr-32">
          <div class="text-c16 font-c4">
            {{ item.name }}
          </div>
        </div>
      </div>

      <div class="w-1/5 ml-2 text-left">
        <span> {{ item.prices[0].amount }} F</span>
      </div>

      <div class="w-32 ml-6 text-left">
<!--        <icon-->
<!--          :data="icons.update"-->
<!--          height="20"-->
<!--          width="20"-->
<!--          class="cursor-pointer"-->
<!--          original-->
<!--          @click="activeUpdate = true"-->
<!--        />-->
      </div>
    </div>
  </div>
</template>

<script>
import config from '../../../../assets/icons/config.svg'
import update from '../../../../assets/icons/update.svg'
import updateFournisor from '../../../popup/updateFournisor'

export default {
  name: "Index",
  components: {
    updateFournisor
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      icons: {
        config,
        update
      },
      allVerre: ['', '', '', '', '', '', ''],
      position: false,
      activeUpdate: false
    }
  },
  methods: {
    activCheck(){
      this.position = ! this.position
    },
    retourUpdate (answer) {
      this.activeUpdate = answer
    }
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}
</style>
