<template>
  <div class="ensemble p-6 pb-12">
    <div class="text-43 text-c12 p-1 special">
      Prix d’achat
    </div>

    <div class="w-full">
      <div class="flex mt-8 w-full">
        <div class="w-1/6" v-if="optionDevise.length > 0">
          <div class="text-c12 lab text-left">
            Prix unitaire ({{ value.toString().substr(0, 6) }} FCFA)
          </div>
          <multiselect :option="optionDevise" :value-t="valueDevise" width="10%" @info="retourDevise" class="mt-2"/>
        </div>

        <div class="w-1/4 ml-6">
          <inputo placeholder="200" class="mt-7" type="number" @info="retourPunit" />
        </div>

        <div class="w-1/4 ml-6" v-if="type === null">
          <div class="text-c12 lab text-left">
            Prix de la paire
          </div>
          <div class="inputo flex items-center pl-3 mt-2">
            <div v-if="response.prixUnit">{{ response.prixUnit * 2}}</div>
          </div>
        </div>

        <div class="w-1/4 ml-6">
          <div class="text-c12 lab text-left">
            Prix CFA
          </div>
          <div class="inputo flex items-center pl-3 mt-2">
            <div v-if="type === null">{{Math.floor(value * (response.prixUnit * 2))}}</div>
            <div v-if="type !== null">{{Math.floor(value * response.prixUnit)}}</div>
          </div>
        </div>
      </div>

      <div class="mt-10 flex items-center">
        <div>Coût de revient :</div>

        <div class="inputo flex items-center pl-3 pr-3 ml-5 bg-f5 w-1/6">
          <div>{{Math.floor((value * (response.prixUnit * 2) * 4) + 5500)}}</div>
        </div>

        <div class="flex items-center ml-6">
          <radios @info="retourTva" />
          <div class="ml-4 text-60 text-c12">
            Assujetie à la TVA (18%)
          </div>
        </div>
      </div>
    </div>

    {{ sendValue }}
  </div>
</template>

<script>
import add from '../../../../assets/icons/addGray.svg'
import inputo from '../../../helper/form/input'
import axios from 'axios'
import multiselect from '../../../helper/form/multiselect'
import radios from '../../../helper/form/radio'

export default {
  name: "Index",

  components: {
    inputo,
    multiselect,
    radios
  },

  props: {
    type: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      icons: {
        add
      },
      response: {},
      charge: false,
      dollar: 655,
      euro: 0,
      yuan: 0,
      rouble: 0,
      valueDevise: 'USD',
      optionDevise: [],
      devise: [],
      value: 655
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
      if (this.response.prixUnit !== undefined ) {
        let response = this.response
        if (this.type === null) {
          response.amount = Math.floor(this.value * (this.response.prixUnit * 2))
          this.$emit('info', response)
        } else {
          response.amount = Math.floor(this.value * this.response.prixUnit)
          this.$emit('info', response)
        }

      }
    }
  },

  created () {
    this.getDevise()
    this.conversion()
  },

  methods: {
    retourCoating(answer){
      this.response.matiere = answer
    },

    retourDevise(answer){
      this.valueDevise = answer
      this.conversion()
    },

    retourColor(answer){
      this.response.color = answer
    },

    retourPunit(answer){
      this.response.prixUnit = parseFloat(answer)
    },

    retourTva (answer) {
      this.response.hasTax = answer
    },

    getDevise(){
      axios.get('https://free.currconv.com/api/v7/currencies?apiKey=b23c673e60ff43958846')
          .then(response => {
            const data = response.data.results
            let tab = []
            let detail = []
            for (const property in data) {
              tab.push(data[property])
              detail.push(data[property].id)
            }
            this.devise = tab
            this.optionDevise = detail
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    conversion(){
      axios.get('https://free.currconv.com/api/v7/convert?q=' + this.valueDevise + '_XOF&compact=ultra&apiKey=b23c673e60ff43958846')
          .then(response => {
            this.value = response.data[this.valueDevise + '_XOF']
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/sass/variables.scss";
.svg-icon {
  fill: white;
}
.inputo{
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  font-family: $font-default;
  height: 40px;
  border-radius: 5px
}
.lab{
  color: #32324D;
}
.ensemble{
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 6px;
}
.special{
  border: 0.8px solid #D6DDE2;
  box-sizing: border-box;
  border-radius: 4px;
  margin: -35px 0px 0px 0px;
  background-color: #D99E82;
  width: 10%;
}
.bout:hover{
  border: 0.5px solid gray;
 // background-color: $hover-color;
}
.diviser {
  height: 1px;
  background-color: #EAEAEF;
}
</style>
