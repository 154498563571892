<template>
  <div class="">
    <div class="w-full flex text-c14 font-c4 text-left items-center item">
      <div class="w-1/2 flex items-center">
        <div
          class="h-6 w-6 checkbox cursor-pointer flex justify-center items-center"
          :class="{'activate': position}"
          @click="activCheck"
        >
          <icon
              :data="icons.valid"
              height="10"
              width="10"
              class="cursor-pointer"
              original
          />
        </div>
        <img
          src="../../../assets/images/jpg/lunette.jpg"
          alt=""
          class="w-1/6 ml-8"
          @click="selected"
        >
        <div
          class="ml-6"
          @click="selected"
        >
          <div class="text-c15 font-c6 tit">
            Emmanuella Optical Frame
          </div>

          <div class="text-c11 mt-2">
            <span class="text-90">Qté dispo :</span> <span> 34</span>

            <span class="ml-4"> 38 000 Fr </span>
            <span class="text-90 ml-2 mr-2"> |</span>
            <span> 38 000 Fr </span>
            <span class="text-90 ml-2 mr-2"> |</span>
            <span> 38 000 Fr </span>
          </div>
        </div>
      </div>

      <div class="w-1/5 ml-4 text-left">
        Ray ban
      </div>

      <div class="w-1/5 ml-2 text-left">
        Gamme
      </div>

      <div class="w-32 ml-2 text-left">
        <div class="w-2/3">
          <inputo type="number" height="25px" background="#F5F5F5" radius="5px" border="1px solid #DDDDDD" size="8px" @info="retourQte"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import option from '../../../assets/icons/option.svg'
import valid from '../../../assets/icons/valid.svg'
import inputo from '../../helper/form/input'

export default {
  name: "Index",
  components: {
    inputo
  },

  props: {
    donne: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        option,
        valid
      },
      allVerre: ['', '', '', '', '', '', ''],
      position: false,
    }
  },

  methods: {
    activCheck(){
      this.position = ! this.position
    },

    selected () {
      this.activeDetail = true
    },

    retourQte (answer) {
      let obj = {
        qte: answer,
        item: this.donne,
        isActive: this.position
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/sass/variables';
.svg-icon{
  fill: transparent;
}
.checkbox{
  border: 1px solid #94A4C4;
  box-sizing: border-box;
  border-radius: 4px;
}

img{
  border-radius: 4.5398px;
  object-fit: cover;
  height: 72.64px;
}
.activate{
  background-color: $oho-blue;
  border: none;
}
.item{
  .icone {
    display: none;
  }
}
.item:hover{
  .icone {
    display: block;
  }
  .tit {
    color: #3521B5;
  }
}
</style>
