<template>
  <div class="ensemble p-6 pb-12">
    <div class="text-43 text-c12 w-1/6 p-1 special bg-yel">
      Caractériques du verre
    </div>

    <div class="flex mt-8">
      <div class="w-1/2">
        <div class="text-c12 lab text-left">
          Sous couche
        </div>
        <div class="mt-2">
          <multiselect
            :option="optionCouche"
            width="30%"
            :value-t="valueCouche"
            @info="retourCouche"
          />
        </div>
      </div>

      <div class="w-1/2 ml-12">
        <div class="text-c12 lab text-left">
          Diamètre
        </div>
        <div class="mt-2 flex">
          <inputo
            placeholder="min"
            class="w-1/2 mr-10"
            type="number"
            @info="retourDiametreMin"
          />
          <inputo
            placeholder="max"
            class="w-1/2"
            type="number"
            @info="retourDiametreMax"
          />
        </div>
      </div>
    </div>

    <div class="flex mt-8">
      <div class="w-1/2">
        <div class="text-c12 lab text-left">
          Traitement AR
        </div>
        <div class="mt-2">
          <multiselect
            :option="optionAr"
            width="30%"
            :value-t="valueAr"
            @info="retourAr"
          />
        </div>
      </div>

      <div class="w-1/2 ml-12">
        <div class="text-c12 lab text-left">
          Sphère
        </div>
        <div class="mt-2 flex">
          <inputo
            placeholder="min"
            class="w-1/2 mr-10"
            type="number"
            @info="retourSphereMin"
          />
          <inputo
            placeholder="max"
            class="w-1/2"
            type="number"
            @info="retourSphereMax"
          />
        </div>
      </div>
    </div>

    <div class="flex mt-8">
      <div class="w-1/2">
        <div class="text-c12 lab text-left">
          Traitement dur
        </div>
        <div class="mt-2">
          <multiselect
            :option="optionDur"
            width="30%"
            :value-t="valueDur"
            @info="retourDur"
          />
        </div>
      </div>

      <div class="w-1/2 ml-12">
        <div class="text-c12 lab text-left">
          Addition
        </div>
        <div class="mt-2 flex">
          <inputo
            placeholder="min"
            type="number"
            class="w-1/2"
            @info="retourAdditionMin"
          />
          <inputo
            placeholder="max"
            type="number"
            class="w-1/2 ml-10"
            @info="retourAdditionMax"
          />
        </div>
      </div>
    </div>

    <div class="flex mt-8">
      <div class="w-1/2">
        <div class="text-c12 lab text-left">
          Cylindre
        </div>
        <div class="mt-2 flex">
          <inputo
            placeholder="min"
            :value-t="0"
            type="number"
            class="w-1/2 mr-10"
            @info="retourCylindreMin"
          />
          <inputo
            placeholder="max"
            class="w-1/2"
            type="number"
            @info="retourCylindreMax"
          />
        </div>
      </div>

      <div class="w-1/2 ml-12">
        <!--              <div class="text-c12 lab text-left">-->
        <!--                Axe-->
        <!--              </div>-->
        <!--              <div class="mt-2">-->
        <!--                <inputo-->
        <!--                  placeholder="12"-->
        <!--                  type="number"-->
        <!--                  @info="retourAxe"-->
        <!--                />-->
        <!--              </div>-->
      </div>
    </div>
    {{ sendValue }}
  </div>
</template>

<script>
import back from '../../../../assets/icons/backBut.svg'
import multiselect from '../../../helper/form/multiselect'
import inputo from '../../../helper/form/input'

export default {
  name: "Index",

  components: {
    multiselect,
    inputo
  },

  data () {
    return {
      icons: {
        back
      },
      optionCouche: ['Durci'],
      valueCouche: 'Cliquez pour selectionner',
      optionDur: ['0', 'BLUMAX', 'TRANSITION 7', 'TRANSITION 8', 'COLORMATIC', 'SUNLITE', 'SUNLITE-BLUMAX', 'XTRACTIVE'],
      valueDur: '0',
      optionAr: ['0', 'HC', 'HMC', 'SATIN+','HMCBLUE'],
      valueAr: '0',
      valueSphere: {
        min: null,
        max: null
      },
      valueCylindre: {
        min: null,
        max: null
      },
      valueAddition: {
        min: null,
        max: null
      },
      valueAxe: '',
      valueDiametre: {
        min: null,
        max: null
      },
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
      if (this.valueSphere.min !== null && this.valueSphere.max !== null && this.valueAddition.min !== null && this.valueAddition.max !== null && this.valueCylindre.max > 0 && this.valueDiametre.max > 0) {
        let obj = {
          underCoat: this.valueCouche,
          treatmentAr: this.valueAr,
          treatmentDur: this.valueDur,
          sphereMin: this.valueSphere.min,
          sphereMax: this.valueSphere.max,
          cylinderMin: this.valueCylindre.min,
          cylinderMax: this.valueCylindre.max,
          additionMin: this.valueAddition.min,
          additionMax: this.valueAddition.max,
          axis: 0,
          diameterMax: this.valueDiametre.max,
          diameterMin: this.valueDiametre.min,
          glassGen: ''
        }
        this.$emit('info', obj)
      }
    }
  },

  methods: {
    retourCouche (answer) {
      this.valueCouche = answer
    },

    retourDur (answer) {
      this.valueDur = answer
    },

    retourAr (answer) {
      this.valueAr = answer
    },

    retourSphereMin (answer) {
      this.valueSphere.min = answer
    },

    retourSphereMax (answer) {
      this.valueSphere.max = answer
    },

    retourCylindreMax (answer) {
      this.valueCylindre.max = answer
    },

    retourCylindreMin (answer) {
      this.valueCylindre.min = answer
    },

    retourAdditionMin (answer) {
      this.valueAddition.min = answer
    },

    retourAdditionMax (answer) {
      this.valueAddition.max = answer
    },

    retourDiametreMax (answer) {
      this.valueDiametre.max = answer
    },
    retourDiametreMin (answer) {
      this.valueDiametre.min = answer
    },

    retourAxe (answer) {
      this.valueAxe = answer
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.lab{
  color: #32324D;
}
.ensemble{
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 6px;
}
.special{
  border: 0.8px solid #D6DDE2;
  box-sizing: border-box;
  border-radius: 4px;
  margin: -35px 0px 0px 0px;
}
</style>
