<template>
  <div class="">
    <div class="flex w-3/4">
      <div class="w-2/3">
        <div class="mt-2">
          <multiselect
              v-if="optionType.length > 0"
            :option="optionType"
            width="15%"
            :value-t="valueType"
            @info="retourType"
          />
        </div>
      </div>

      <div class="w-1/3 ml-6">
        <div class="mt-2">
          <upload @info="retourImage" />
        </div>
      </div>
    </div>

    {{ sendValue }}
  </div>
</template>

<script>
import back from '../../../../assets/icons/backBut.svg'
import multiselect from '../../../helper/form/multiselect'
import upload from '../../../helper/form/upload2'
import apiroutes from '../../../../router/api-routes'
import http from '../../../../plugins/https'

export default {
  name: "Index",

  components: {
    multiselect,
    upload
  },
  props: {
    index: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
      icons: {
        back
      },
      optionType: ['CORDONS', 'ESSUIS', 'ETUIS', 'SOLUTION POUR LENTILLE', 'LENTILLES', 'PRODUIT D\'ENTRETIEN'],
      valueType: '',
      image: null,
      accessoire: []
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
        let obj = {
          index: this.index,
          type: this.valueType,
          image: '',
        }
        this.$emit('info', obj)
    }
  },

  created () {
    // this.allAccess()
  },

  methods: {
    retourType (answer) {
      this.valueType = answer
    },
    //
    // retourImage (answer) {
    //   this.image = answer
    // }

    allAccess () {
      http.get(apiroutes.baseURL + apiroutes.getProduct + '?type=ACCESSORY')
          .then(response => {
            this.accessoire = response.reverse()
            console.log(response)
            let tab = []
            for (let item in this.accessoire) {
              tab.push(this.accessoire[item].name)
            }
            this.valueType = tab[0]
            this.optionType = tab
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.lab{
  color: #32324D;
}

</style>
