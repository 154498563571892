<template>
  <div class="">
    <detail
      v-if="activeDetail"
      :activation="activeDetail"
      :donnes="donnes"
      type="Accessoire"
      @oga="retourDetail"
    />

    <div class="w-full flex text-c14 font-c4 text-left items-center item cursor-pointer" @click="selected">
      <div class="w-1/2 flex items-center">
        <div
          class="h-6 w-6 checkbox cursor-pointer flex justify-center items-center"
          :class="{'activate': position}"
          @click="activCheck"
        >
          <icon
              :data="icons.valid"
              height="10"
              width="10"
              original
          />
        </div>
        <img
          src="../../../assets/images/jpg/etuie.jpg"
          alt=""
          class="w-1/6 ml-8"
        >
        <div
          class="ml-6"
        >
          <div class="text-60 font-c5 text-c12">
            {{ donnes.brands[0].label }}
          </div>
          <div class="text-c11 mt-2">
            <span class="text-c15 font-c6 tit">{{ donnes.name }}</span>
            <span class="text-90 ml-4">Qté dispo :</span> <span> {{ donnes.quantity.toLocaleString() }}</span>
          </div>
        </div>
      </div>

      <div class="w-1/5 ml-10 text-left">
        {{ donnes.brands[0].label }}
      </div>
<!--      <div class="w-1/6 ml-2 text-left">-->
<!--        <span v-if="donnes.ranges.length > 0">{{ donnes.ranges[0].label }}</span>-->
<!--      </div>-->
      <div class="w-1/5 ml-2 font-c6 text-c15 text-left">
        <span v-if="donnes.prices.length > 0">{{ donnes.prices[0].amount.toLocaleString() }} F ...</span>
        <span v-if="donnes.prices.length === 0">-</span>
      </div>
      <div class="w-48 ml-2">
        <icon
          :data="icons.option"
          height="25"
          width="25"
          class="icone cursor-pointer"
          original
        />
      </div>
    </div>
  </div>
</template>

<script>
import option from '../../../assets/icons/option.svg'
import detail from '../../popup/detailProduct'
import valid from '../../../assets/icons/valid.svg'

export default {
  name: "Index",
  components: {
    detail
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        option,
        valid
      },
      allVerre: ['', '', '', '', '', '', ''],
      position: false,
      activeDetail: false
    }
  },

  methods: {
    activCheck () {
      this.position = ! this.position
    },

    retourDetail (answer) {
      this.activeDetail = answer
    },

    selected () {
      this.activeDetail = true
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/sass/variables';

.svg-icon{
  fill: transparent;
}
.checkbox{
  border: 1px solid #94A4C4;
  box-sizing: border-box;
  border-radius: 4px;
}

img{
  border-radius: 4.5398px;
  object-fit: cover;
  height: 72.64px;
}
.activate{
  background-color: $oho-blue;
}
.item{
  .icone {
    display: none;
  }
}
.item:hover{
  .icone {
    display: block;
  }
  .tit {
    color: #3521B5;
  }
}
</style>
