<template>
  <div class="ensemble p-6 pb-12">
    <div class="text-43 text-c12 w-1/6 p-1 special bg-rose">
      Prix de vente
    </div>

    <div class="flex justify-center">
      <easy-spinner
          v-if="charge && optionChange.length === 0"
          type="dots"
          size="30"
      />
    </div>

    <div class="w-full" v-if="optionChange.length > 0">
      <div class="flex mt-8 w-3/4">
        <div class="w-2/3">
          <div class="text-c12 lab text-left">
            Montant
          </div>
        </div>

        <div class="w-1/3 ml-6">
          <div class="text-c12 lab text-left">
            Type de prix
          </div>
        </div>
      </div>
    </div>

    <div v-if="optionChange.length > 0">
      <price
        v-for="(item, index) in test"
        :key="index"
        :option="optionChange"
        :index="index"
        @info="retourPrice"
      />
    </div>

    <div class="mt-10" v-if="optionChange.length > 0">
      <div
        class="flex bg-ef h-26 items-center justify-center w-1/6 rounded-7 cursor-pointer bout"
        @click="addPrice"
      >
        <icon
          :data="icons.add"
          height="15"
          width="15"
          class="mr-2 cursor-pointer"
          original
        />
        <div class="text-60 text-c12">
          Ajouter un prix
        </div>
      </div>
    </div>
    {{ sendValue }}
  </div>
</template>

<script>
import add from '../../../../assets/icons/addGray.svg'
import price from './priceTemplate'
import apiroutes from '../../../../router/api-routes'
import http from '../../../../plugins/https'

export default {
  name: "Index",

  components: {
    price
  },

  data () {
    return {
      icons: {
        add
      },
      valuePrice: [],
      test: [""],
      allPrices: [],
      temoinPrice: [],
      charge: true
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
      if (this.valuePrice.length === this.test.length) {
        let tab = []
        for (let x in this.valuePrice){
          for (let y in this.temoinPrice) {
            if (this.temoinPrice[y].label === this.valuePrice[x].type){
              let obj = {
                amount: this.valuePrice[x].amount,
                product: null,
                category: this.temoinPrice[y].id
              }
              tab.push(obj)
            }
          }
        }

        this.$emit('info', tab)
      }
    },

    optionChange: function () {
      return this.allPrices
    }
  },

  created () {
    this.allPrix()
  },

  methods: {
    allPrix () {
      http.get(apiroutes.baseURL + apiroutes.allPrice)
          .then(response => {
            const all = response.reverse()
            let tab = []
            for (let item in all) {
              tab.push(all[item].label)
            }
            this.allPrices = tab
            this.temoinPrice = all
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    retourPrice (answer) {
      if (this.valuePrice[answer.index] !== undefined && this.valuePrice[answer.index] !== null) {
        if (answer.type !== this.valuePrice[answer.index].type){
          this.allPrices.push(this.valuePrice[answer.index].type)
        }
      }

      for (let b in this.allPrices) {
        if (this.allPrices[b] === answer.type) {
          this.allPrices.splice(b, 1)
        }
      }
      this.valuePrice[answer.index] = answer
    },

    addPrice(){
      this.test.push('')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/sass/variables.scss";
.svg-icon {
  fill: white;
}
.lab{
  color: #32324D;
}
.ensemble{
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 6px;
}
.special{
  border: 0.8px solid #D6DDE2;
  box-sizing: border-box;
  border-radius: 4px;
  margin: -35px 0px 0px 0px;
}
.bout:hover{
  border: 0.5px solid gray;
 // background-color: $hover-color;
}
</style>
