<template>
  <div class="ensemble p-6 pb-12">
    <div class="text-43 text-c12 w-1/6 p-1 special bg-greenF">
      Fournisseur
    </div>

    <div class="w-full">
      <div class="flex mt-8 w-full">
        <div class="w-1/3" v-if="optionFournisseur.length > 0">
          <div class="text-c12 lab text-left">
            Fournisseur
          </div>
          <multiselect :option="optionFournisseur" :value-t="valueFournisseur" @info="retourFournisseur"/>
        </div>

        <div class="w-1/3 ml-6">
          <div class="text-c12 lab text-left">
            Code
          </div>
          <inputo placeholder="FFSV" class="mt-2" @info="retourCode" />
        </div>

        <div class="w-1/3 ml-6">
          <div class="text-c12 lab text-left">
            Brand name
          </div>
          <inputo placeholder="Nona Plus SV" class="mt-2" @info="retourBrand" />
        </div>

      </div>

      <div class="flex mt-8 w-full">
        <div class="w-1/3">
          <div class="text-c12 lab text-left">
            Indice
          </div>
          <inputo placeholder="1,50 CLEAR " class="mt-2" @info="retourIndice" />
        </div>

        <div class="w-1/3 ml-6">
          <div class="text-c12 lab text-left">
            Coating
          </div>
          <inputo placeholder="HC" class="mt-2" @info="retourCoating" />
        </div>

        <div class="w-1/3 ml-6">
          <div class="text-c12 lab text-left">
            Color
          </div>
          <inputo placeholder="Bleu" class="mt-2" @info="retourColor" />
        </div>

      </div>

      <div class="flex mt-8 w-full">
        <div class="w-1/3" v-if="valueFournisseur === 'STOCK OHO'">
          <div class="text-c12 lab text-left">
            Stock de rupture
          </div>
          <inputo placeholder="0" type="number" class="mt-2" @info="retourRupture" />
        </div>

        <div class="w-1/3 ml-6" />
        <div class="w-1/3 ml-6" />
      </div>

    </div>

    {{ sendValue }}
  </div>
</template>

<script>
import add from '../../../../assets/icons/addGray.svg'
import inputo from '../../../helper/form/input'
import axios from 'axios'
import multiselect from '../../../helper/form/multiselect'
import apiroutes from '../../../../router/api-routes'
import http from '../../../../plugins/https'

export default {
  name: "Index",

  components: {
    inputo,
    multiselect
  },

  data () {
    return {
      icons: {
        add
      },
      allSupplier: [],
      response: {},
      charge: false,
      valueDollars: 1,
      valueFournisseur: '',
      optionFournisseur: [],
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
      if (this.response.code !== undefined && this.response.brand !== undefined && this.response.indice !== undefined) {
        this.$emit('info', this.response)
      }
    }
  },

  created () {
    this.allFournisseur()
  },

  methods: {
    allFournisseur () {
      http.get(apiroutes.baseURL + apiroutes.allSupplier)
          .then(response => {
            this.allSupplier = response.reverse()
            let tab = []
            for (let item in this.allSupplier) {
              tab.push(this.allSupplier[item].name)
            }
            this.valueFournisseur = tab[0]
            this.optionFournisseur = tab
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    retourFournisseur (answer) {
      for (let item in this.allSupplier) {
        if (this.allSupplier[item].name === answer){
          this.response.supplier = this.allSupplier[item].id
        }
      }
      this.valueFournisseur = answer
    },

    retourCode (answer) {
      this.response.code = answer
    },

    retourRupture (answer) {
      this.response.primaryLimitation = answer
    },

    retourBrand (answer) {
      this.response.brand = answer
    },

    retourIndice (answer) {
      this.response.indice = answer
    },

    retourCoating(answer){
      this.response.coating = answer
    },

    retourColor(answer){
      this.response.color = answer
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/sass/variables.scss";
.svg-icon {
  fill: white;
}
.inputo{
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  font-family: $font-default;
  height: 40px;
  border-radius: 5px
}
.lab{
  color: #32324D;
}
.ensemble{
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 6px;
}
.special{
  border: 0.8px solid #D6DDE2;
  box-sizing: border-box;
  border-radius: 4px;
  margin: -35px 0px 0px 0px;
}
.bout:hover{
  border: 0.5px solid gray;
 // background-color: $hover-color;
}
.diviser {
  height: 1px;
  background-color: #EAEAEF;
}
</style>
