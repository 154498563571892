<template>
  <div class="">
    <detail
      v-if="activeDetail"
      :activation="activeDetail"
      :donnes="selectedItem"
      type="Verre"
      @oga="retourDetail"
    />
    <div class="w-full flex text-90 text-c18 font-c6 text-left">
      <div class="w-1/4">
        CODE COMMERCIAL
      </div>
      <div class="w-1/5 ml-4">
        MATIÈRE
      </div>
      <div class="w-1/6 ml-6">
        S/COUCHE
      </div>
      <div class="w-68 ml-4">
        IND.
      </div>
      <div class="w-92 ml-4">
        CYL.
      </div>
      <div class="w-68 ml-4">
        AXE
      </div>
      <div class="w-92 ml-4">
        ADD.
      </div>
      <div class="w-1/6 ml-4">
        PRIX
      </div>
      <div class="w-6 mr-4" />
      <div />
    </div>

    <div class="flex justify-center mt-12" v-if="charge && allVerre.length === 0">
      <easy-spinner
          type="dots"
          size="30"
      />
    </div>

    <div
      v-for="(item, index) in tracker"
      :key="index"
      class="w-full flex items-center text-c14 font-c4 text-left mt-6 item pt-6 cursor-pointer"
      @click="selected(item)"
    >
      <div class="w-1/4">
<!--        <div class="font-c5">-->
<!--          {{item.name.substr(0, 17)}} <span v-if="item.name.length > 17">..</span>-->
<!--        </div>-->
        <div>{{item.ref}}</div>
      </div>

      <div class="w-1/5 ml-4">
        {{item.glassMaterial}}
      </div>

      <div class="w-1/6 ml-6">
        <span v-if="item.glassDetails !== null">
          {{item.glassDetails.underCoat}}
        </span>
        <span v-if="!item.glassDetails">
          -
        </span>
      </div>

      <div class="w-68 ml-4">
        {{item.indice}}
      </div>

      <div class="w-92 ml-4 text-c13">
         <span v-if="item.glassDetails !== null">
         {{item.glassDetails.cylinderMin}}/{{item.glassDetails.cylinderMax}}
        </span>
        <span v-if="!item.glassDetails">
          -
        </span>
      </div>

      <div class="w-40 ml-4">
        <span v-if="item.glassDetails !== null">
          {{item.glassDetails.axis}}
        </span>
        <span v-if="!item.glassDetails">
          -
        </span>
      </div>

      <div class="w-92 ml-4 text-c13">
       <span v-if="item.glassDetails !== null">
          {{item.glassDetails.additionMin}}/{{item.glassDetails.additionMax}}
        </span>
        <span v-if="!item.glassDetails">
          -
        </span>
      </div>

      <div class="w-1/6 ml-4 text-yel">
          <span v-if="item.prices.length > 0">
            {{ item.prices[0].amount.toLocaleString() }} Fr
          </span>
      </div>
      <div class="w-6 mr-4">
        <icon
          :data="icons.option"
          height="25"
          width="25"
          class="icone"
          original
        />
      </div>
    </div>

    <div class="flex mt-10 justify-end pb-16 pr-10">
      <div class="w-1/3 flex items-center justify-end">
        <div class="text-c16 font-c6">
          Page {{ page }} sur {{ totalPage }}
        </div>
        <icon
            :data="icons.prev"
            height="50"
            width="50"
            class="icone cursor-pointer ml-4"
            @click="prev"
            original
        />
        <icon
            :data="icons.next"
            height="50"
            width="50"
            class="icone cursor-pointer ml-4"
            @click="next"
            original
        />
      </div>
    </div>
  </div>
</template>

<script>
import option from '../../../assets/icons/option.svg'
import detail from '../../popup/detailProduct'
import http from "../../../plugins/https"
import apiroutes from "../../../router/api-routes"
import prev from '../../../assets/icons/prev.svg'
import next from '../../../assets/icons/next.svg'

export default {
  name: "Index",
  components: {
    detail
  },

  props: {
    search: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        option,
        prev,
        next
      },
      allVerre: [],
      activeDetail: false,
      selectedItem: {
        name: 'test'
      },
      charge: true,

      page: 1,
      limite: 20,
      total: 0,
      totalPage: 0,
      allVerrePaginate: []
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    tracker: function () {
      let retour = this.allVerrePaginate

      const obj = Object.keys(this.search)
      if (this.search !== null && obj.length > 0) {
        retour = this.allVerre
        // filtre on Glass Family
        if (this.search.famille !== undefined && this.search.famille !== ''){
          let type = 'PROGRESSIVE'
          if (this.search.famille === 'Dégressif'){
            type = 'DEGRESSIVE'
          }
          if (this.search.famille === 'Double foyer'){
            type = 'BIFOCAL'
          }
          if (this.search.famille === 'Simple Foyer'){
            type = 'SINGLE_FOCUS'
          }
          retour = retour.filter((item) => {
            if (item.glassType !== undefined && (item.glassType.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
              return item
            }
          })
        }

        //Sous Couche
        if (this.search.sousCouche !== undefined){
          let type = this.search.sousCouche

          retour = retour.filter((item) => {
            if (item.glassDetails !== null) {
              if (item.glassDetails !== undefined && (item.glassDetails.underCoat.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
                return item
              }
            }
          })
        }

        //DUR
        if (this.search.dur !== undefined){
          let type = this.search.dur

          retour = retour.filter((item) => {
            if (item.glassDetails !== undefined && item.glassDetails !== null && item.glassDetails.treatmentDur !== null ){
              if ((item.glassDetails.treatmentDur.replace(/ /g, "").normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.replace(/ /g, "").toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
                return item
              }
            }
          })
        }

        //AR
        if (this.search.ar !== undefined){
          let type = this.search.ar

          retour = retour.filter((item) => {
            if (item.glassDetails !== undefined && item.glassDetails !== null && item.glassDetails.treatmentAr !== null ) {
              if ((item.glassDetails.treatmentAr.replace(/ /g, "").normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.replace(/ /g, "").toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
                return item
              }
            }
          })
        }

        //FABRICATION
        if (this.search.fabrication !== undefined  && this.search.fabrication !== ''){
          let type = this.search.fabrication
          let secondType = ''

          if (this.search.fabrication === 'TRADITIONELLE') {
            secondType = 'TRADITIONNELLE'
          }

          if (this.search.fabrication === 'FREE FORME') {
            secondType = 'FREE FORM'
          }

          retour = retour.filter((item) => {
            if (item.meta !== null && item.meta.fabrication !== undefined) {
              if (item.meta.fabrication.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === type.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() || item.meta.fabrication.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() === secondType.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()) {
                return item
              }
            }
          })
        }
      }


      return retour
    }
  },

  created () {
    this.allProductPaginate()
    this.allProduct()
  },

  methods: {
    prev () {
      if (this.page > 1) {
        this.page = this.page - 1
        this.allProductPaginate()
      }
    },

    next () {
      if (this.totalPage > this.page) {
        this.page = this.page + 1
        this.allProductPaginate()
      }
    },

    retourDetail (answer) {
      this.activeDetail = answer
    },

    selected (item) {
      this.selectedItem = item
      this.activeDetail = true
    },

    allProduct () {
      http.get(apiroutes.baseURL + apiroutes.getProduct + '?type=GLASS')
          .then(response => {
            // console.log(response)
            this.allVerre = response
            this.charge = false
            this.$emit('totalVerre', response.length)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            if (error.status === 401) {
              window.location.reload()
            }
          })
    },

    allProductPaginate () {
      this.charge = true
      const body = {
        page: this.page,
        limit: this.limite,
        type: "GLASS",
      }

      http.post(apiroutes.baseURL + apiroutes.getProductParginate, body)
          .then(response => {
            this.total = response.total
            this.allVerrePaginate = response.data
            const page = parseInt(this.total)/parseInt(this.limite)

            if (page > parseInt(page)) {
              this.totalPage = parseInt(page) + 1
            } else {
              this.totalPage = parseInt(page)
            }
            this.charge = false
            this.$emit('totalVerre', this.total)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item{
  border-top: 1px solid #DDDDDD;
  .icone {
    display: none;
  }
}
.item:hover{
 .icone {
   display: block;
 }
}
</style>
