<template>
  <div class="">
    <div class="w-full flex text-B text-c18 font-c4 text-left mb-10">
      <div class="w-3/5">
        INTITULÉ
      </div>
      <div class="w-1/5 ml-2">
        PRIX
      </div>
      <div class="w-32 ml-6" />
    </div>

    <div
      v-if="charge"
      class="flex justify-center mt-16"
    >
      <easy-spinner
        type="dots"
        size="70"
      />
    </div>

    <div v-if="!charge">
      <div
        v-for="(item, index) in tracker"
        :key="index"
        class="mt-4"
      >
        <div
          v-if="index > 0"
          class="item mt-4"
        />
        <liste
          class="mt-4"
          :item="item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import liste from './liste'
import apiroutes from '../../../../router/api-routes'
import http from '../../../../plugins/https'

export default {
  name: "Index",
  components: {
    liste
  },
  data () {
    return {
      allPrestation: [],
      charge: true
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    tracker: function () {
      let retour = this.allPrestation

      this.$emit('totalVerre', retour.length)
      return retour
    }
  },

  created () {
    this.allInfo()
  },

  methods: {
    allInfo(){
      http.get(apiroutes.baseURL + apiroutes.getProduct + '?type=SERVICE')
          .then(response => {
            this.allPrestation = response
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
      this.allPrest()
    },

    allPrest () {
      http.get(apiroutes.baseURL + apiroutes.getProduct + '?type=TREATMENT')
          .then(response => {
            for (let item in response) {
              this.allPrestation.push(response[item])
            }
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    }
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item{
  border-top: 1px solid #DDDDDD;
  .icone {
    display: none;
  }
}
.item:hover{
  .icone {
    display: block;
  }
}
.all{
  min-height: 400px;
}
</style>
