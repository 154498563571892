<template>
  <div class="">
    <div class="flex items-center">
      <div class="w-2/3 flex text-left text-c48 items-center font-c6">
        <icon
          :data="icons.back"
          height="70"
          width="70"
          class="mr-2 cursor-pointer"
          original
          @click="fermer"
        />
        <div class="ml-4">
          Nouveau verre
        </div>
      </div>
    </div>

    <div class="mt-6 rounded-10 h-auto bg-white w-full p-8 pl-14 pr-14 pb-10">
      <div class="text-c20 font-c6 text-left">
        Informations commerciales
      </div>

      <div class="mt-10">
        <form1 @info="retourform1" />
        <form2
          class="mt-12"
          @info="retourform2"
        />

        <div class="flex mt-10">
          <div class="w-full">
            <div class="text-c12 lab text-left">
              Nom du verre
            </div>
            <div class="mt-2 bloc p-4 text-left pl-6 pr-6 rounded-10">
              <div
                v-if="response.form1 !== undefined && response.form2 !== undefined"
                class="text-60"
              >
                Verre <span v-if="response.form1.glassType === 'PROGRESSIVE'">PROGRESSIF</span>
                <span v-if="response.form1.glassType === 'DEGRESSIVE'">DEGRESSIF</span>
                <span v-if="response.form1.glassType === 'BIFOCAL'">DOUBLE FOYER</span>
                <span v-if="response.form1.glassType === 'SINGLE_FOCUS'">SIMPLE FOYER</span>
                Mat {{ response.form1.glassMaterial.toUpperCase() }}, {{ response.form1.indice }} Sc {{ response.form2.underCoat.toUpperCase() }}
                Tdur {{ response.form2.treatmentDur.toUpperCase() }} AR {{ response.form2.treatmentAr.toUpperCase() }}
                Sph {{ response.form2.sphereMin }}/{{ response.form2.sphereMax }} Cyl {{ response.form2.cylinderMin }}/{{ response.form2.cylinderMax }}
              </div>
            </div>
          </div>
        </div>



        <form3
          class="mt-12"
          @info="retourform3"
        />

        <form5
          class="mt-16"
          @info="retourform5"
        />

        <formAchat
          class="mt-16"
          @info="retourAchat"
        />

        <form4
          class="mt-16 mb-10"
          @info="retourform4"
        />

        <div class="w-full">
          <div class="text-left text-c12 mt-8">
            Observation
          </div>
          <textare
            class="mt-2"
            background="#F2F5F9"
            color="#606060"
            radius="5px"
            @info="retourNote"
          />
        </div>
      </div>
    </div>

    <div
      v-if="error !== null"
      class="mt-6 text-red text-c16"
    >
      {{ error }}
    </div>

    <div class="mt-12 flex justify-end">
      <div class="w-1/3 flex">
        <bouton
          label="Annuler"
          background="white"
          radius="6px"
          color="#303030"
          class="w-1/3"
          @oga="fermer"
        />
        <bouton
          label="Ajouter le verrre"
          radius="6px"
          :charge="charge"
          class="w-2/3 ml-6"
          @oga="continuer"
        />
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../../assets/icons/backBut.svg'
import form1 from './verre/form1'
import form2 from './verre/form2'
import form3 from './verre/form3'
import form4 from './accessoire/form2'
import form5 from './verre/form5'
import formAchat from './verre/formAchat'
import bouton from '../../helper/add/button'
import http from "../../../plugins/https"
import apiroutes from "../../../router/api-routes"
import textare from '../../helper/form/textarea'

export default {
  name: "Index",

  components: {
    form1,
    form2,
    form3,
    form4,
    form5,
    bouton,
    formAchat,
    textare
  },

  data () {
    return {
      icons: {
        back
      },
      response: {},
      error: null,
      charge: false
    }
  },
  methods: {
    fermer(){
      this.$emit('oga', false)
    },
    retourform1 (answer) {
      this.response.form1 = answer
      console.log(this.response)
    },
    retourform2 (answer) {
      this.response.form2 = answer
      console.log(this.response)
    },
    retourform3 (answer) {
      this.response.form3 = answer
      console.log(this.response)
    },
    retourform4 (answer) {
      this.response.form4 = answer
      console.log(this.response)
    },
    retourform5 (answer) {
      this.response.form5 = answer
      console.log(this.response)
    },
    retourNote (answer) {
      this.response.note = answer
      console.log(this.response)
    },

    retourAchat (answer) {
      this.response.achat = answer
      console.log(this.response)
    },

    continuer () {
      if (this.response.form1 !== undefined && this.response.form2 !== undefined && this.response.form3 !== undefined && this.response.form4 !== undefined && this.response.form5 !== undefined) {
        let donne = this.response.form1
        this.response.form2.glassGen = this.response.form1.meta.generation
        donne.glassDetails = this.response.form2
        donne.quantity = this.response.form3.quantity
        donne.price = this.response.achat.amount
        donne.hasSecond = this.response.form3.hasSecond
        donne.hasTax = this.response.achat.hasTax
        donne.sellType = this.response.form3.sellType
        donne.supplier = this.response.form5.supplier
        donne.primaryLimitation = 0
        donne.meta.supplier = this.response.form5
        donne.observation = this.response.note

        this.save(donne)
      } else {
        this.error = 'Assurez vous d\'avoir entrer toutes les données du verre pour continuer'
      }
    },

    save (donne) {
      this.charge = true
      http.post(apiroutes.baseURL + apiroutes.createProduct, donne)
          .then(response => {
            this.savePrice(response.id)
          })
          .catch(error => {
            this.charge = false
            console.log(error)

            if (error.data.message === 'Please create principal stock agency'){
              this.error = "L'agence de Stock n'existe pas. Veuillez l'ajouter et ressayer"
            } else {
              this.error = "Une erreur s'est produite. Veuillez ressayer"
            }
          })
    },

    savePrice (id) {
      const price = this.response.form4
      for (let i in price) {
        price[i].product = id
      }

      http.post(apiroutes.baseURL + apiroutes.saveProductPrice, {prices: price})
          .then(response => {
            this.$emit('oga', false)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = "Problème de connexion. Veuillez ressayer"
          })
    },
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.bloc{
  background-color: #FFF6E6;
}
</style>
