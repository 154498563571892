<template>
  <div class="">
    <div class="w-full flex text-90 text-c18 font-c6 text-left">
      <div class="w-1/2">
        NOM COMMERCIAL
      </div>
      <div class="w-1/5 ml-10">
        MARQUE
      </div>
<!--      <div class="w-1/6 ml-2">-->
<!--        GAMME-->
<!--      </div>-->
      <div class="w-1/5 ml-2">
        Prix
      </div>
      <div class="w-48 ml-2" />
      <div />
    </div>

    <div class="item mt-6" />

    <div class="flex justify-center mt-12">
      <easy-spinner
          v-if="charge && allVerre.length === 0"
          type="dots"
          size="30"
      />
    </div>

    <div
      v-for="(item, index) in tracker"
      :key="index"
      class="mt-6"
    >
      <liste :donnes="item"/>
    </div>
  </div>
</template>

<script>
import option from '../../../assets/icons/option.svg'
import liste from './accessoireListe'
import http from "../../../plugins/https"
import apiroutes from "../../../router/api-routes"

export default {
  name: "Index",
  components: {
    liste
  },

  props: {
    response: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        option
      },
      allVerre: [],
      charge: true
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    tracker: function () {
      let retour = this.allVerre
      console.log(this.response)
      if (this.response !== null){
        //Marque
        if (this.response.marque !== null && this.response.marque !== undefined ) {
          retour = retour.filter((item) => {
            for(let i in item.brands) {
              if (item.brands[i].label !== undefined && item.brands[i].label === this.response.marque) {
                return item
              }
            }

          })
        }

        //Disponibilité
        if (this.response.dispo !== null && this.response.dispo !== undefined) {
          // retour = retour.filter((item) => {
          //   for(let i in item.brands) {
          //     if (item.brands[i].label === this.response.marque) {
          //       return item
          //     }
          //   }
          //
          // })
        }
      }

      this.$emit('totalVerre', retour.length)
      return retour
    }
  },

  created () {
    this.allProduct()
  },

  methods: {
    allProduct () {
      http.get(apiroutes.baseURL + apiroutes.getProduct + '?type=ACCESSORY')
          .then(response => {
            this.allVerre = response
            console.log(this.allVerre)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item{
  border-top: 1px solid #DDDDDD;
  .icone {
    display: none;
  }
}
.item:hover{
 .icone {
   display: block;
 }
}
</style>
