<template>
  <div class="">
    <div class="flex">
      <div class="w-full flex">
        <div class="w-1/2">
          <div class="text-c12 lab text-left">
            Fournisseur
          </div>
          <div class="mt-2" v-if="optionFournisseur.length > 0">
            <multiselect
                :option="optionFournisseur"
                width="21%"
                :value-t="valueFournisseur"
                @info="retourFournisseur"
            />
          </div>
        </div>

        <div class="w-1/2 ml-6">
          <div>
            <div class="text-c12 lab text-left">
              Quantité
            </div>
            <div class="mt-2">
              <inputo
                  type="number"
                  placeholder="100"
                  @info="retourQte"
              />
            </div>
          </div>
        </div>

<!--        <div class="w-1/3 ml-6">-->
<!--          <div class="text-c12 lab text-left">-->
<!--            Stock de rupture-->
<!--          </div>-->
<!--          <div class="mt-2">-->
<!--            <inputo-->
<!--                type="number"-->
<!--                placeholder="100"-->
<!--                @info="retourLimite"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>

    {{ sendValue }}
  </div>
</template>

<script>
import back from '../../../../assets/icons/backBut.svg'
import multiselect from '../../../helper/form/multiselect'
import inputo from '../../../helper/form/input'
import http from "../../../../plugins/https"
import apiroutes from "../../../../router/api-routes"

export default {
  name: "Index",

  components: {
    multiselect,
    inputo,
  },

  data () {
    return {
      icons: {
        back
      },
      allSupplier: [],
      valueQte: '',
      secondPaie: 'NON',
      charge: true,
      limite: '',
      valueFournisseur: '',
      optionFournisseur: []
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
      if (this.valueQte) {
        let obj = {
          quantity: parseInt(this.valueQte),
          supplier: this.valueFournisseur,
          primaryLimitation: this.limite
        }

        for (let i in this.allSupplier) {
          if (this.valueFournisseur === this.allSupplier[i].name) {
            obj.supplier = this.allSupplier[i].id
          }
        }

        this.$emit('info', obj)
      }
    }
  },

  created () {
    this.allFournisseur()
  },

  methods: {
    allFournisseur () {
      http.get(apiroutes.baseURL + apiroutes.allSupplier)
          .then(response => {
            this.allSupplier = response.reverse()
            let tab = []
            for (let item in this.allSupplier) {
              tab.push(this.allSupplier[item].name)
            }
            this.valueFournisseur = tab[0]
            this.optionFournisseur = tab
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    retourQte (answer) {
      this.valueQte = answer
      console.log(answer)
    },

    retourLimite(answer) {
      this.limite = answer
    },

    retourFournisseur (answer) {
      this.valueFournisseur = answer
    },

    retourVente (answer) {
      this.valueVente = answer
    },

    retourPaie (answer) {
      this.secondPaie = answer
    },

    retourTva (answer) {
      this.tva = answer
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.lab{
  color: #32324D;
}
</style>
