<template>
  <div class="ensemble p-6 pb-12">
    <div class="text-43 text-c12 w-1/6 p-1 special bg-greenF">
      Accessoires
    </div>

    <div class="w-full">
      <div class="flex mt-8 w-3/4">
        <div class="w-2/3">
          <div class="text-c12 lab text-left">
            Libellé
          </div>
        </div>

        <div class="w-1/3 ml-6">
          <div class="text-c12 lab text-left">
            Image
          </div>
        </div>
      </div>
    </div>

    <accessoire
      v-for="(item, index) in test"
      :key="index"
      :index="index"
      class="mb-2"
      @info="retourPrice"
    />

    <div class="mt-10">
      <div
        class="flex bg-ef h-26 items-center justify-center w-1/5 rounded-7 cursor-pointer bout"
        @click="addAccessoire"
      >
        <icon
          :data="icons.add"
          height="15"
          width="15"
          class="mr-2 cursor-pointer"
          original
        />
        <div class="text-60 text-c12">
          Ajouter un accessoire
        </div>
      </div>
    </div>
    {{ sendValue }}
  </div>
</template>

<script>
import add from '../../../../assets/icons/addGray.svg'
import accessoire from './accessoire'

export default {
  name: "Index",

  components: {
    accessoire
  },

  data () {
    return {
      icons: {
        add
      },
      valuePrice: [],
      test: [""]
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
      if (this.valuePrice.length > 0) {
        this.$emit('info',  this.valuePrice)
      }
    }
  },

  methods: {
    retourPrice (answer) {
      this.valuePrice[answer.index] = answer
    },

    addAccessoire () {
      this.test.push('')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/sass/variables.scss";
.svg-icon {
  fill: white;
}
.lab{
  color: #32324D;
}
.ensemble{
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 6px;
}
.special{
  border: 0.8px solid #D6DDE2;
  box-sizing: border-box;
  border-radius: 4px;
  margin: -35px 0px 0px 0px;
}
.bout:hover{
  border: 0.5px solid gray;
 // background-color: $hover-color;
}
</style>
