<template>
  <div class="">
    <div class="w-full flex text-90 text-c18 font-c6 text-left">
      <div class="w-1/2">
        NOM COMMERCIAL
      </div>
      <div class="w-1/5 ml-2">
        MARQUE
      </div>
      <div class="w-1/5 ml-2">
        GAMME
      </div>
      <div class="w-48 ml-2" />
      <div />
    </div>

    <div class="item mt-6" />

    <div class="flex justify-center mt-12" v-if="charge && allVerre.length === 0">
      <easy-spinner
          type="dots"
          size="30"
      />
    </div>

    <div
      v-for="(item, index) in tracker"
      :key="index"
      class="mt-6"
    >
      <liste :donnes="item" />
    </div>

    <div class="flex mt-10 justify-end pb-16 pr-10">
      <div class="w-1/3 flex items-center justify-end">
        <div class="text-c16 font-c6">
          Page {{ page }} sur {{ totalPage }}
        </div>
        <icon
            :data="icons.prev"
            height="50"
            width="50"
            class="icone cursor-pointer ml-4"
            @click="prev"
            original
        />
        <icon
            :data="icons.next"
            height="50"
            width="50"
            class="icone cursor-pointer ml-4"
            @click="next"
            original
        />
      </div>
    </div>
  </div>
</template>

<script>
import option from '../../../assets/icons/option.svg'
import liste from './montureListe'
import http from "../../../plugins/https"
import apiroutes from "../../../router/api-routes"
import prev from '../../../assets/icons/prev.svg'
import next from '../../../assets/icons/next.svg'

export default {
  name: "Index",
  components: {
    liste
  },
  props: {
    response: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        option,
        prev,
        next
      },
      allVerre: [],
      charge: true,
      page: 1,
      limite: 20,
      total: 0,
      totalPage: 0,
      allVerrePaginate: [],
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    tracker: function () {
      let retour = this.allVerrePaginate

      if (this.response.marque !== null && this.response.marque !== undefined ||
          this.response.gamme !== null && this.response.gamme !== undefined) {

        retour = this.allVerre
        //Marque
        if (this.response.marque !== null && this.response.marque !== undefined) {
          retour = retour.filter((item) => {
            for (let i in item.brands) {
              if (item.brands[i].label === this.response.marque) {
                return item
              }
            }

          })
        }

        //Disponibilité
        if (this.response.dispo !== null && this.response.dispo !== undefined) {
          // retour = retour.filter((item) => {
          //   for(let i in item.brands) {
          //     if (item.brands[i].label === this.response.marque) {
          //       return item
          //     }
          //   }
          //
          // })
        }

        //GAMME
        if (this.response.gamme !== null && this.response.gamme !== undefined) {
          retour = retour.filter((item) => {
            for (let i in item.ranges) {
              if (item.ranges[i].label === this.response.gamme) {
                return item
              }
            }

          })
        }
      }

      return retour
    }
  },

  created () {
    this.allProductPaginate()
    this.allProduct()
  },

  methods: {
    prev () {
      if (this.page > 1) {
        this.page = this.page - 1
        this.allProductPaginate()
      }
    },

    next () {
      if (this.totalPage > this.page) {
        this.page = this.page + 1
        this.allProductPaginate()
      }
    },

    allProduct () {
      http.get(apiroutes.baseURL + apiroutes.getProduct + '?type=FRAMES')
          .then(response => {
            this.allVerre = response
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    allProductPaginate () {
      this.charge = true
      http.post(apiroutes.baseURL + apiroutes.getProductParginate, {
        "page": this.page,
        "limit": this.limite,
        "type": "FRAMES"
      })
          .then(response => {
            this.total = response.total
            this.allVerrePaginate = response.data
            const page = parseInt(this.total)/parseInt(this.limite)

            if (page > parseInt(page)) {
              this.totalPage = parseInt(page) + 1
            } else {
              this.totalPage = parseInt(page)
            }
            this.$emit('totalVerre', this.total)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item{
  border-top: 1px solid #DDDDDD;
  .icone {
    display: none;
  }
}
.item:hover{
 .icone {
   display: block;
 }
}
</style>
