<template>
  <div class="">
    <div class="flex">
      <div class="w-1/2">
        <div class="text-c12 lab text-left">
          Famille de verre
        </div>
        <div class="mt-2">
          <multiselect
            :option="optionType"
            width="33%"
            :value-t="valueType"
            @info="retourType"
          />
        </div>
      </div>

      <div class="w-1/2 ml-12">
        <div class="text-c12 lab text-left">
          Génération de verre
        </div>
        <div class="mt-2">
          <multiselect
            v-if="optionGeneration !== null"
            :option="optionGeneration"
            width="33%"
            :value-t="generation"
            @info="retourGeneration"
          />
        </div>
      </div>
    </div>

    <div class="flex mt-6">
      <div class="w-1/2">
        <div class="text-c12 lab text-left">
          Code commercial
        </div>
        <div class="mt-2">
          <inputo
            placeholder="DC2312"
            @info="retourCode"
          />
        </div>
      </div>

      <div class="w-1/2 ml-12">
        <div class="text-c12 lab text-left">
          Nom du verre
        </div>
        <div class="mt-2">
          <inputo
            placeholder="DC2312"
            @info="retourNom"
          />
        </div>
      </div>
    </div>

    <div class="flex mt-6">
      <div class="w-1/2">
        <div class="text-c12 lab text-left">
          Indice
        </div>
        <div class="mt-2">
          <inputo
            placeholder="DC2312"
            type="number"
            @info="retourIndice"
          />
        </div>
      </div>

      <div class="w-1/2 ml-12">
        <div class="text-c12 lab text-left">
          Matière
        </div>
        <div class="mt-2">
          <multiselect
            :option="optionMatiere"
            width="33%"
            :value-t="valueMatiere"
            @info="retourMatiere"
          />
        </div>
      </div>
    </div>

    <div class="flex mt-6">
      <div class="w-1/2">
        <div class="text-c12 lab text-left">
          Type de fabrication
        </div>
        <div class="mt-2">
          <multiselect
            :option="optionFabriq"
            width="32%"
            :value-t="valueFabriq"
            @info="retourFabriq"
          />
        </div>
      </div>

      <div class="w-1/2 ml-12" />
    </div>
    {{ sendValue }}
  </div>
</template>

<script>
import back from '../../../../assets/icons/backBut.svg'
import multiselect from '../../../helper/form/multiselect'
import inputo from '../../../helper/form/input'

export default {
  name: "Index",

  components: {
    multiselect,
    inputo
  },

  data () {
    return {
      icons: {
        back
      },
      optionType: ['Progressif', 'Dégressif', 'Double foyer', 'Simple Foyer'],
      valueType: 'Progressif',
      optionMatiere: [ 'CR39', 'POLY', 'TRIVEX'],
      valueMatiere: 'CR39',
      optionFabriq: [ 'TRADITIONELLE', 'NUMÉRIQUE', 'FREE FORME'],
      valueFabriq: 'TRADITIONELLE',
      optionGeneration: ['Progressif FREE', 'Progressif MY VIEW', 'Progressif NDRIVE', 'Progressif NOVA'],
      valueCode: '',
      valueIndice: '',
      generation: 'Cliquez pour selectionner',
      nom: ''
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
      if (this.valueCode.length > 0 && this.valueIndice.length > 0 && this.generation.length > 0 && this.nom.length > 0) {
        let obj = {
          glassType: this.valueType,
          glassMaterial: this.valueMatiere,
          indice: this.valueIndice,
          type: 'GLASS',
          name: this.nom,
          ref: this.valueCode,
          meta: {
            supplier: null,
            generation: this.generation,
            fabrication: this.valueFabriq
          }
        }
        this.$emit('info', obj)
      }
    }
  },

  methods: {
    retourType (answer) {

      if (answer === 'Progressif') {
        this.optionGeneration = ['Progressif FREE', 'Progressif MY VIEW', 'Progressif NDRIVE', 'Progressif NOVA']
        this.valueType = 'PROGRESSIVE'
      }
      if (answer === 'Dégressif') {
        this.optionGeneration = ['Progressif FREE', 'Progressif MY VIEW', 'Progressif NDRIVE', 'Progressif NOVA']
        this.valueType = 'DEGRESSIVE'
      }
      if (answer === 'Double foyer') {
        this.optionGeneration = ['DOUBLE FOYER STANDARD', 'DOUBLE FOYER BIOMETRIC']
        this.valueType = 'BIFOCAL'
      }
      if (answer === 'Simple Foyer') {
        this.optionGeneration = ['Simple Foyer générique', 'Simple Foyer commande BIOMETRIC', 'SIMPLE FOYER DE COMMANDE NUMERIQUE DRIVE']
        this.valueType = 'SINGLE_FOCUS'
      }
    },

    retourGeneration (answer) {
      this.generation = answer
    },

    retourFabriq (answer) {
      this.valueFabriq = answer
    },

    retourCode (answer) {
      this.valueCode = answer
    },

    retourMatiere (answer) {
      this.valueMatiere = answer
    },

    retourIndice (answer) {
      this.valueIndice = answer
    },

    retourNom (answer) {
      this.nom = answer
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.lab{
  color: #32324D;
}
</style>
