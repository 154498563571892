<template>
  <div class="">
    <div class="flex">
      <div class="w-3/4 flex">
        <div class="w-1/2">
          <div class="text-c12 lab text-left">
            Type de vente
          </div>
          <div class="mt-2">
            <multiselect
                :option="optionVente"
                width="24%"
                :value-t="valueVente"
                @info="retourVente"
            />
          </div>
        </div>

        <div class="w-1/2 ml-6">
            <div>
              <div class="text-c12 lab text-left">
                Quantité
              </div>
              <div class="mt-2">
                <inputo
                    placeholder="100"
                    @info="retourQte"
                />
              </div>
            </div>
        </div>
      </div>

      <div class="w-1/4 ml-6 pl-6">
        <div class="text-c12 lab text-left">
          Bénéficie de la deuxième paire
        </div>
        <div class="mt-2 flex">
          <bouton
            v-if="secondPaie === 'NON'"
            label="OUI"
            radius="4px 0px 0px 4px"
            size="12px"
            border="1px solid #DCDCE4"
            class="w-1/2"
            background="white"
            height="38px"
            color="#666687"
            @info="retourPaie"
          />
          <bouton
            v-if="secondPaie === 'OUI'"
            label="OUI"
            radius="4px 0px 0px 4px"
            size="12px"
            class="w-1/2"
            height="38px"
            @info="retourPaie"
          />
          <bouton
            v-if="secondPaie === 'NON'"
            label="NON"
            radius="0px 4px 4px 0px"
            size="12px"
            class="w-1/2"
            height="38px"
            @info="retourPaie"
          />
          <bouton
            v-if="secondPaie === 'OUI'"
            label="NON"
            radius="0px 4px 4px 0px"
            size="12px"
            border="1px solid #DCDCE4"
            color="#666687"
            background="white"
            class="w-1/2"
            height="38px"
            @info="retourPaie"
          />
        </div>
      </div>
    </div>

    {{ sendValue }}
  </div>
</template>

<script>
import back from '../../../../assets/icons/backBut.svg'
import multiselect from '../../../helper/form/multiselect'
import inputo from '../../../helper/form/input'
import bouton from '../../../helper/add/button'

export default {
  name: "Index",

  components: {
    multiselect,
    inputo,
    bouton,
  },

  data () {
    return {
      icons: {
        back
      },
      optionVente: ['Vente principale', 'Vente seconde paire'],
      valueVente: 'Vente principale',
      valueQte: '',
      valueAchat: '',
      secondPaie: 'NON',
      charge: true,
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
      if (this.valueVente !== '' && this.valueQte) {
        let obj = {
          quantity: parseInt(this.valueQte),
          hasSecond: false,
          sellType: this.valueVente,
        }

        if (this.secondPaie === 'OUI'){
          obj.hasSecond = true
        }

        if (this.valueVente === this.optionVente[0]){
          obj.sellType = 'PRINCIPAL'
        }

        if (this.valueVente === this.optionVente[1]){
          obj.sellType = 'SECOND'
        }

        this.$emit('info', obj)
      }
    }
  },

  created () {
  },

  methods: {
    retourQte (answer) {
      this.valueQte = answer
      console.log(answer)
    },

    retourAchat (answer) {
      this.valueAchat = answer
    },

    retourFournisseur (answer) {
      this.valueFournisseur = answer
    },

    retourVente (answer) {
      this.valueVente = answer
    },

    retourPaie (answer) {
      this.secondPaie = answer
    },

    retourTva (answer) {
      this.tva = answer
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.lab{
  color: #32324D;
}
</style>
