<template>
  <div class="">
    <div class="w-full flex text-B text-c18 font-c4 text-left mb-10">
      <div class="w-3/6">
        INTITULÉ
      </div>
      <div class="w-1/6 ml-2 text-center">
        DEBUT
      </div>
      <div class="w-1/6 ml-2 text-center">
        FIN
      </div>
      <div class="w-20 ml-6" />
    </div>

    <div
      v-if="charge"
      class="flex justify-center mt-16"
    >
      <easy-spinner
        type="dots"
        size="70"
      />
    </div>

    <div class="mt-12 text-center text-90 text-c18" v-if="tracker.length === 0">
      Aucune categorie de prix disponible
    </div>

    <div
      v-for="(item, index) in tracker"
      :key="index"
      class="mt-4"
    >
      <div
        v-if="index > 0"
        class="item mt-4"
      />
      <liste
        class="mt-4"
        :item="item"
        @deleteItem="allPrix.splice(index, 1)"
        @reload="allInfo"
      />
    </div>
  </div>
</template>

<script>
import liste from './liste'
import apiroutes from '../../../../router/api-routes'
import http from '../../../../plugins/https'

export default {
  name: "Index",
  components: {
    liste
  },
  data () {
    return {
      allPrix: [],
      charge: true
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    tracker: function () {
      let retour = this.allPrix

      this.$emit('totalVerre', retour.length)
      return retour
    }
  },

  created () {
    this.allInfo()
  },

  methods: {
    allInfo(){
      http.get(apiroutes.baseURL + apiroutes.allPrice)
          .then(response => {
            this.allPrix = response
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    }
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item{
  border-top: 1px solid #DDDDDD;
  .icone {
    display: none;
  }
}
.item:hover{
  .icone {
    display: block;
  }
}
.all{
  min-height: 400px;
}
</style>
