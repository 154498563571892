<template>
  <div class="rounded-10 h-auto bg-white w-full p-8 all">
    <div class=" w-full">
      <div
        class="w-full"
        @click="activeGamme = activeMarque = activeDispo = false"
      >
        <steep-select
          v-if="steep.length > 0"
          :option="steep"
          :value="steepValue"
          @info="retourSteep"
        />
      </div>

      <div class="text-c18 text-left mt-12">
        <span class="text-c34 font-c7">{{ totalVerre }}</span> {{ steepValue }}
      </div>

      <div class="w-3/6 flex mt-12" v-if="steepValue !== 'Prix' && steepValue !== 'Prestations' && steepValue !== 'Verres'">
        <!--Disponibilité-->
        <multiselect :option="optionDispo" :value-t="dispoValue" @info="retourDispo" class="w-1/3"/>
        <div class="w-1/3 ml-3" v-if="steepValue !== 'Accessoires'">
          <multiselect :option="optionGamme" :value-t="gammeValue" @info="retourGamme" class="w-full" />
        </div>

        <multiselect :option="optionMarque" :value-t="marqueValue" @info="retourMarque" class="w-1/3 ml-3"/>
      </div>

      <div v-if="steepValue !== 'Prix' && steepValue === 'Verres'">
        <div class="w-5/6 flex mt-4">
          <multiselect :option="optionFabriq" :value-t="valueFabriq" @info="retourFabriq" class="w-1/5"/>
          <multiselect :option="optionFamille" :value-t="valueFamille" @info="retourFam" class="w-1/5 ml-3"/>
          <multiselect :option="optionSC" :value-t="valueSC" @info="retourSC" class="w-1/5 ml-3"/>
          <multiselect :option="optionDur" :value-t="valueDur" @info="retourDur" class="w-1/5 ml-3"/>
          <multiselect :option="optionAr" :value-t="valueAr" @info="retourAr" class="w-1/5 ml-3"/>
        </div>
      </div>
    </div>

    <div
      class="mt-12 liste p-6"
      @click="activeGamme = activeMarque = activeDispo = false"
    >
      <verresListe v-if="steepValue === 'Verres'" :search="sender" @totalVerre="retourCount"/>
      <monture-liste v-if="steepValue === 'Montures'" :response="sender" @totalVerre="retourCount"/>
      <accessoire v-if="steepValue === 'Accessoires'" :response="sender" @totalVerre="retourCount"/>
      <prestation v-if="steepValue === 'Prestations'" @totalVerre="retourCount"/>
      <prix v-if="steepValue === 'Prix'" @totalVerre="retourCount"/>
    </div>
  </div>
</template>

<script>
import arrow from '../../../assets/icons/arrow.svg'
import steepSelect from '../../helper/form/steepSelect'
import agenda from '../../../assets/icons/agenda.svg'
import down from '../../../assets/icons/down.svg'
import verresListe from './verresListe'
import montureListe from './monture'
import accessoire from './accessoire'
import prestation from './prestations/prestationTemplate'
import prix from './prix/prixTemplate'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'
import multiselect from '../../helper/form/multiselect'

export default {
  name: "Index",
  components: {
    steepSelect,
    verresListe,
    montureListe,
    accessoire,
    prestation,
    prix,
    multiselect
  },

  data () {
    return {
      icons: {
        arrow,
        agenda,
        down
      },
      steep: ['Verres', 'Montures', 'Accessoires', 'Prestations', 'Prix'],
      steepValue: 'Verres',
      optionDispo: ['Disponible', 'Indisponible'],
      dispoValue: 'Disponibilité',
      activeDispo: false,

      optionGamme: ['Premium', 'Gold'],
      gammeValue: 'Gamme',
      activeGamme: false,

      optionMarque: ['Gucci', 'LV'],
      marqueValue: 'Marque',
      activeMarque: false,

      optionFamille: ['Progressif', 'Dégressif', 'Double foyer', 'Simple Foyer'],
      valueFamille: 'Famille',
      optionDur: ['0', 'BLUMAX', 'TRANSITION 7', 'TRANSITION 8', 'COLORMATIC', 'SUNLITE', 'SUNLITE-BLUMAX', 'XTRACTIVE'],
      valueDur: 'DUR',
      optionAr: ['0', 'HC', 'HMC', 'SATIN+'],
      valueAr: 'AR',
      optionFabriq: [ 'TRADITIONELLE', 'NUMÉRIQUE', 'FREE FORME'],
      valueFabriq: 'Fabrication',

      optionMatiere: ['Optique', 'Simple'],
      valueMatiere: 'Matière',
      optionSC: ['Durci'],
      valueSC: 'Sous Couche',
      response: {},
      totalVerre: 0
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sender: function () {
      return this.response
    }
  },

  created (){
    this.allGammes()
    this.allMarques()
  },

  methods: {
    retourCount (answer) {
      this.totalVerre = answer
    },

    retourDispo(answer){
      this.activeDispo = false
      if (answer !== this.dispoValue && answer !== null) {
        this.response.dispo = answer
      }
    },

    retourSteep(answer){
      this.steepValue = answer
      this.$emit('label', answer)
    },

    retourGamme(answer){
      this.activeGamme = false
      if (answer !== this.gammeValue && answer !== null){
        this.response.gamme = answer
      }
    },

    retourFabriq (answer) {
      if (answer !== this.valueFabriq) {
        this.response.fabrication = answer
      }
    },

    retourMarque(answer){
      this.activeMarque = false
      if (answer !== this.marqueValue && answer !== null){
        this.response.marque = answer
      }
    },

    retourFam (answer) {
      if (answer !== this.valueFamille) {
        this.response.famille = answer
      }
    },
    retourDur (answer) {
      if (answer !== this.valueDur) {
        this.response.dur = answer
      }
    },
    retourAr (answer) {
      if (answer !== this.valueAr) {
        this.response.ar = answer
      }
    },
    retourSC (answer) {
      if (answer !== this.valueSC) {
        this.response.sousCouche = answer
      }
    },

    allMarques () {
      http.get(apiroutes.baseURL + apiroutes.allMarque)
          .then(response => {
            let tab = []
            for (let item in response) {
              tab.push(response[item].label)
            }
            this.optionMarque = tab
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    allGammes () {
      http.get(apiroutes.baseURL + apiroutes.allGamme)
          .then(response => {
            let tab = []
            for (let item in response) {
              tab.push(response[item].label)
            }
            this.optionGamme = tab
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    }
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.filtr{
  background: rgba(242, 245, 249, 0.5);
  border: 0.5px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  height: 36.39px;
}
.liste{
  border: 0.480025px solid #CCCFD2;
  box-sizing: border-box;
  border-radius: 4.80025px;
}
.compFiltre{
  width: auto;
  min-width: 9.1%;
}
.all {
  min-height: 600px;
}
</style>
